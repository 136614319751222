<template>
  <form-wizard :title="null" :subtitle="null" color="#1890ff">
    <template slot="footer" slot-scope="props">
      <div class="wizard-footer-left mt-2">
        <b-button
          v-if="props.activeTabIndex > 0"
          @click="props.prevTab()"
          variant="outline-primary"
          :disabled="isSubmitting"
        >
          <feather-icon icon="ChevronLeftIcon" class="mr-50" />
          <span class="align-middle">Voltar</span>
        </b-button>
      </div>
      <div class="wizard-footer-right mt-2">
        <b-button
          v-if="!props.isLastStep"
          @click="next_step(props)"
          variant="info"
          :disabled="isNext"
        >
          <div v-if="!isNext">
            <span class="align-middle">Próximo</span>
            <feather-icon icon="ChevronRightIcon" class="ml-50" />
          </div>
          <b-spinner v-else small />
        </b-button>

        <b-button
          v-else
          @click="create_appointment"
          variant="info"
          style="min-width: 125px"
          :disabled="isSubmitting"
        >
          <div v-if="!isSubmitting">
            <span class="align-middle">Salvar</span>
            <feather-icon icon="ChevronRightIcon" class="ml-50" />
          </div>
          <b-spinner v-else small />
        </b-button>
      </div>
    </template>
    <tab-content title="Dados iniciais." icon="feather icon-user" class="mb-2">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Empresa *">
            <v-select
              v-model="companiesSelected"
              :options="companies"
              autocomplete="off"
              :clearable="false"
              @input="changeCompany(companiesSelected)"
              :loading="isloadingCompany"
            >
              <template v-slot:option="option">
                <h6 class="text-capitalize">{{ option.label }}</h6>
              </template>
            </v-select>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Disciplina *">
            <v-select
              v-model="disciplineSelected"
              :options="disciplines"
              searchable
              @search="fetchDisciplines"
              :loading="isloadingDiscipline"
            >
              <template v-slot:option="option">
                <h6 class="text-capitalize">{{ option.label }}</h6>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando ... <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else
                  >Digite o Nome da Disciplina</em
                >
              </template>
            </v-select>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Professor *">
            <v-select
              v-model="teacherSelected"
              :options="teachers"
              searchable
              @search="fetchTeachers"
              :loading="isloadingTeacher"
            >
              <template v-slot:option="option">
                <h6 class="text-capitalize">{{ option.label }}</h6>
                <small v-if="option.email">
                  {{ option.email }}
                </small>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando ... <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else>Digite o Nome</em>
              </template>
            </v-select>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Duração *">
            <flat-pickr
              v-model="duration"
              placeholder="Duração"
              class="form-control"
              :config="{
                enableTime: true,
                noCalendar: true,
                dateFormat: 'H:i',
                time_24hr: true,
              }"
            />
          </b-form-group>
        </div>
      </div>
    </tab-content>
    <tab-content title="Dados finais." icon="feather icon-user" class="mb-2">
      <div class="row">
        <div class="col-12">
          <b-form-group label="Estúdio">
            <v-select
              v-model="studioSelected"
              :options="studios"
              searchable
              @search="fetchStudios"
              :loading="isloadingStudio"
            >
              <template v-slot:option="option">
                <h6 class="text-capitalize">{{ option.label }}</h6>
              </template>
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando ... <em>{{ search }}</em
                  >.
                </template>
                <em style="opacity: 0.5" v-else>Digite o Nome</em>
              </template>
            </v-select>
          </b-form-group>
        </div>
        <div class="col-12">
          <b-form-group label="Operador">
            <v-select
              v-if="operators"
              v-model="operatorsSelected"
              :options="operators"
              autocomplete="off"
              :clearable="false"
            />
          </b-form-group>
        </div>
        <b-col cols="12">
          <b-form-group label="Ocorrência">
            <b-form-textarea
              id="textarea-rows-occurrence"
              v-model="occurrence"
              placeholder="Informe uma descrição da ocorrência"
              rows="3"
            />
          </b-form-group>
        </b-col>
      </div>
    </tab-content>
  </form-wizard>
</template>
<script>
import { BCalendar } from "bootstrap-vue";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import _teachersService from "@/services/teachers-service";
import _operatorsService from "@/services/operators-service";
import _appointmentService from "@/services/appointment-service";
import _disciplineService from "@/services/discipline-service";
import _coursesService from "@/services/courses-service";
import _occurrenceService from "@/services/audiovisual/job-occurrence-service";
import _lessonService from "@/services/lessons-service";
import _companieService from "@/services/company-service";
import _projectService from "@/services/projects-service";

import moment from "moment";
export default {
  components: {
    BCalendar,
    FormWizard,
    TabContent,
  },
  data() {
    return {
      debounceMilliseconds: 300,
      todayDate: "",
      teachers: [],
      teacherSelected: null,
      isloadingTeacher: false,
      isSubmitting: false,
      operators: [],
      operatorsSelected: null,
      studios: [],
      studioSelected: null,
      isloadingStudio: false,
      disciplines: [],
      disciplineSelected: null,
      classificationSelected: null,
      isloadingDiscipline: false,
      occurrence: "",
      duration: "01:00",
      lesson_id: 0,
      isNext: false,
      start: null,
      job_type_id: 4, //GRAVAÇÃO OFF
      appointment_id: 0,
      appointment_studio_id: 0,
      appointment_operator_id: 0,
      occurrence_category_id: process.env.VUE_APP_APP_TECH_OCCURRENCE,
      project_id: 0,
      type_id: 2, //AULA
      companiesSelected: null,
      companies: [],
      isloadingCompany: false
    };
  },
  created() {
    this.getDate();
    this.getOperators();
    this.getCompanies();
  },
  methods: {
    fetchTeachers(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.isloadingTeacher) {
            this.isloadingTeacher = true;
            _teachersService
              .autoComplete(_search)
              .then((res) => {
                this.teachers = this.populardropdown(
                  res.content,
                  "name",
                  "id",
                  "email"
                );
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloadingTeacher = false));
          }
        }, this.debounceMilliseconds);
      }
    },
    fetchStudios(_search) {
      if (_search && _search.length >= 3) {
        this.timeout = setTimeout(() => {
          if (!this.isloadingStudio) {
            this.isloadingStudio = true;

            const today = new Date();
            const today_start = new Date(today.getTime() + 300000);
            this.start = moment(today_start).format("YYYY-MM-DDTHH:mm");

            _appointmentService
              .showAllStudios(
                _search,
                this.start,
                this.duration,
                false,
                0,
                null,
                true,
                this.job_type_id,
                this.lesson_id //ID da aula que vai ser gravada antes
              )
              .then((res) => {
                if (res && res.content && res.content.length > 0) {
                  this.studios = this.$utils.populardropdown(
                    res.content,
                    "name",
                    "id",
                    false,
                    false,
                    false
                  );
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloadingStudio = false));
          }
        }, this.debounceMilliseconds);
      }
    },
    populardropdown(_list, _texto, _valor, _email) {
      let retorno = [];
      retorno.push(
        ..._list.map((m) => {
          return {
            label: m[_texto],
            value: m[_valor],
            email: m[_email],
          };
        })
      );
      return retorno;
    },
    getOperators() {
      _operatorsService
        .showOperatorsWithUser()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.operators = this.$utils.populardropdown(
              res.content,
              "name",
              "id",
              false,
              false,
              false
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    fetchDisciplines(_search) {
      if (_search && _search.length >= 3) {
        setTimeout(() => {
          if (!this.isloadingDiscipline) {
            this.isloadingDiscipline = true;
            _disciplineService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.disciplines = this.$utils.populardropdown(
                    res.content,
                    "name",
                    "id",
                    false,
                    false,
                    false
                  );
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.isloadingDiscipline = false));
          }
        }, this.debounceMilliseconds);
      }
    },
    async next_step(props) {
      if (!this.disciplineSelected || !this.disciplineSelected.value) {
        this.$utils.toastError(
          "Notificação",
          "Informe uma disciplina."
        );
        return;
      }
      if (!this.teacherSelected || !this.teacherSelected.value) {
        this.$utils.toastError("Notificação", "Informe um professor.");
        return;
      }
      if (!this.duration || this.duration == "00:00") {
        this.$utils.toastError("Notificação", "Informe a duração.");
        return;
      }

      await this.get_courses_teacher(props);
      //await this.create_course(props);
    },
    async get_courses_teacher(props) {
      if (this.isNext) return;

      this.isNext = true;
      let is_lesson = false;
      _lessonService
        .getLessonTeacher(this.teacherSelected.value, this.project_id)
        .then((res) => {
          if (res && res.content && res.content.length > 0) {
            //PEGA APENAS OS QUE NÃO TEM AGENDAMENTO E ORDEM 1 (GARANTE PARA NÃO PEGAR CURSO QUE JA TENHA AGENDAMENTO)
            let lst_not_scheduled = res.content.filter(
              (f) => !f.scheduled && f.order == 1
            );

            if (lst_not_scheduled && lst_not_scheduled.length > 0) {
              //VERIFICA SE EXISTE ALGUM COM A MESMA DISCIPLINA E DURAÇÃO
              let lst_discipline = lst_not_scheduled.filter(
                (f) =>
                  f.discipline_id == this.disciplineSelected.value &&
                  f.duration == `${this.duration}:00`
              );

              if (lst_discipline && lst_discipline.length > 0) {
                //VERIFICA SE NÃO EXISTE AGENDAMENTO PARA AQUELE CURSO.
                lst_discipline.forEach((element) => {
                  if (this.lesson_id > 0) return;

                  let lst_courses = res.content.filter(
                    (f) =>
                      f.course_id === element.course_id && f.id != element.id
                  );

                  if (lst_courses && lst_courses.length > 0) {
                    let lst_schedule = lst_courses.filter((f) => f.scheduled);
                    if (lst_schedule && lst_schedule.length > 0) return;
                  }

                  this.lesson_id = element.id;
                  is_lesson = true;
                });
              } else {
                //VERIFICA SE EXISTE ALGUM COM A MESMA DISCIPLINA QUE POSSA SER ALTERADO
                let lst_discipline = lst_not_scheduled.filter(
                  (f) => f.discipline_id == this.disciplineSelected.value
                );

                let id_lesson_update = 0;
                if (lst_discipline && lst_discipline.length > 0) {
                  //VERIFICA SE NÃO EXISTE AGENDAMENTO PARA AQUELE CURSO.
                  lst_discipline.forEach((element) => {
                    if (id_lesson_update > 0) return;

                    let lst_courses = res.content.filter(
                      (f) =>
                        f.course_id === element.course_id && f.id != element.id
                    );

                    if (lst_courses && lst_courses.length > 0) {
                      let lst_schedule = lst_courses.filter((f) => f.scheduled);
                      if (lst_schedule && lst_schedule.length > 0) return;
                    }

                    id_lesson_update = element.id;
                  });
                }

                //NÃO ACHOU COM A DISCIPLINA, ENTÃO PEGA O PRIMEIRO DISPONIVEL
                if (id_lesson_update == 0) {
                  lst_not_scheduled.forEach((element) => {
                    if (id_lesson_update > 0) return;

                    let lst_courses = res.content.filter(
                      (f) =>
                        f.course_id === element.course_id && f.id != element.id
                    );

                    if (lst_courses && lst_courses.length > 0) {
                      let lst_schedule = lst_courses.filter((f) => f.scheduled);
                      if (lst_schedule && lst_schedule.length > 0) return;
                    }

                    id_lesson_update = element.id;
                  });
                }
                //ACHOU UM CURSO DISPONIVEL PARA ALTERAÇÃO
                if (id_lesson_update > 0) {
                  let course_update = res.content.filter(
                    (f) => f.id === id_lesson_update
                  )[0];
                  this.lesson_id = id_lesson_update;
                  this.update_course(props, course_update.course_id);
                }
              }
            }
          }

          //CASO NÃO ACHE, ENTÃO DEVE SER CRIADO
          if (this.lesson_id <= 0) {
            this.create_course(props);
          } else {
            //SE ACHOU UM COMPATIVEL, SEGUE PARA O PRÓXIMO STEP
            if (is_lesson) {
              this.isNext = false;
              props.nextTab();
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally();
    },

    async update_course(props, id = 0) {
      let dto = {
        id: id,
        name: `${this.disciplineSelected.label}`,
        project_id: this.project_id,
        lesson_number: 1,
        duration: this.duration,
        discipline_id: this.disciplineSelected.value,
        teachers: [
          {
            teacher_id: this.teacherSelected.value,
          },
        ],
      };

      let payload = new FormData();
      payload.append("dados", JSON.stringify(dto));

      const _createOrUpdate = _coursesService.update(payload);

      this.isloading = true;
      _createOrUpdate
        .then((res) => {
          if (
            res &&
            res.response &&
            res.response.lessons &&
            res.response.lessons.length > 0
          ) {
            this.lesson_id = res.response.lessons[0].id;
            this.isNext = false;
            props.nextTab();
          }
        })
        .catch((error) => (this.lesson_id = 0))
        .finally();
    },
    async create_course(props, id = 0) {
      let dto = {
        id: id,
        name: `${this.disciplineSelected.label}`,
        project_id: this.project_id,
        lesson_number: 1,
        duration: this.duration,
        discipline_id: this.disciplineSelected.value,
        teachers: [
          {
            teacher_id: this.teacherSelected.value,
          },
        ],
      };

      let payload = new FormData();
      payload.append("dados", JSON.stringify(dto));

      const _createOrUpdate =
        id <= 0
          ? _coursesService.create(payload)
          : _coursesService.update(payload);

      this.isloading = true;
      _createOrUpdate
        .then((res) => {
          if (
            res &&
            res.response &&
            res.response.lessons &&
            res.response.lessons.length > 0
          ) {
            this.lesson_id = res.response.lessons[0].id;
            this.isNext = false;
            props.nextTab();
          }
        })
        .catch((error) => (this.lesson_id = 0))
        .finally();
    },
    async create_appointment() {
      if (!this.studioSelected || !this.studioSelected.value) {
        this.$utils.toastError("Notificação", "Informe um estúdio.");
        return;
      }

      if (!this.operatorsSelected || !this.operatorsSelected.value) {
        this.$utils.toastError("Notificação", "Informe um operador.");
        return;
      }

      if (!this.occurrence || this.occurrence.length < 5) {
        this.$utils.toastError("Notificação", "Informe a ocorrência.");
        return;
      }

      if (!this.lesson_id || this.lesson_id <= 0) {
        this.$utils.toastError(
          "Notificação",
          "Não foi possível encontrar a aula."
        );
        return;
      }

      //await this.create_app();
      await this.create_immediate_appointment();
    },
    async create_immediate_appointment() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      let payload = {
        start: this.start,
        lessonId: this.lesson_id,
        studioId: this.studioSelected.value,
        operatorId: this.operatorsSelected.value,
        occurrenceDescription: this.occurrence
      };

      try {
        await _appointmentService.createImmediateSchedule(payload);
        this.$utils.toast("Notificação", "Criado com sucesso.");
        this.$emit("finish_app");
      } catch (error) {
        this.$utils.toastError("Notificação", error);
      } finally {
        this.isSubmitting = false;
      }
    },
    // obsolete
    async create_app() {
      if (this.isSubmitting) return;

      this.isSubmitting = true;

      let appointmentDTO = {
        appointment_central_tech: true,
        id: 0,
        start: this.start,
        name: null,
        description: null,
        send_notification: false,
        accept_recording: false,
        available_youtube: true,
        recurrenceItens: [],
        studios: [
          {
            studio_id: this.studioSelected.value,
            main: true,
          },
        ],
        type: this.type_id,
        recording_pending: false,
        event_id: null,
        lesson_id: this.lesson_id,
        duration: `${this.duration}:00`,
        job_type_id: this.job_type_id,
        is_streaming: false,
      };

      let payload = {
        ...appointmentDTO,
      };

      const payload_ = { data: { ...payload } };

      const _createOrUpdate = _appointmentService.create(payload_);
      _createOrUpdate
        .then((res) => {
          var error = false;
          if (res && res.content) {
            this.appointment_id = res.content.id;
            if (res.content.studios && res.content.studios.length > 0) {
              this.appointment_studio_id = res.content.studios[0].id;
              if (
                res.content.studios[0].appointmentStudiosOperator &&
                res.content.studios[0].appointmentStudiosOperator.length > 0
              ) {
                this.appointment_operator_id =
                  res.content.studios[0].appointmentStudiosOperator[0].id;
              }
            } else error = true;
          } else error = true;

          if (error) {
            this.$utils.toastError(
              "Notificação",
              "Não foi possível criar o agendamento"
            );
          } else {
            this.create_operator_scale();
            this.create_occurrence_app();
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    // obsolete
    async create_operator_scale() {
      await this.operatorScale();
    },
    // obsolete
    async create_occurrence_app() {
      await this.occurrence_app();
    },
    // obsolete
    async operatorScale() {
      this.isSubmitting = true;

      let upsert = {
        studiosOperator: {},
      };
      upsert.studiosOperator.appointment_studio_id = this.appointment_studio_id;
      upsert.studiosOperator.operator_id = this.operatorsSelected.value;
      upsert.studiosOperator.id = this.appointment_operator_id;
      if (
        this.appointment_operator_id == null ||
        this.appointment_operator_id == 0
      ) {
        upsert.example_id = true;
      }

      const payload = { data: { ...upsert } };
      //promisse
      const _createOrUpdate = _appointmentService.upSertScaleOperators(payload);

      _createOrUpdate
        .then((res) => {})
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isSubmitting = false));
    },
    // obsolete
    occurrence_app() {
      this.isSubmitting = true;
      const insert = [
        {
          description: this.occurrence,
          relation_key: this.appointment_id,
          data_key: 'appointments',
          occurrence_category_id: this.occurrence_category_id,
        },
      ];

      const payload = { data: insert };
      _occurrenceService
        .severalCreate(payload)
        .then((res) => {
          this.$utils.toast("Notificação", "Criado com sucesso.");
          this.$emit("finish_app");
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isSubmitting = false));
    },
    getDate() {
      const today = new Date();
      this.todayDate =
        (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) +
        "/" +
        (today.getMonth() + 1 < 10
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1) +
        "/" +
        today.getFullYear();
    },
    getCompanies() {
      _companieService
        .showAll()
        .then((res) => {
          this.companies = this.$utils.populardropdown(
            res.content,
            "name",
            "id"
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    changeCompany(companiesSelected) {
      if (companiesSelected && companiesSelected.value > 0) {
        this.getProjectSystemDefault(companiesSelected.value);
      }
    },
    getProjectSystemDefault(company) {
      this.isloadingCompany = true;
      _projectService
        .findByCompanySystemDefault(company)
        .then((res) => {
          if (res && res.content && res.content.id) {
            this.project_id = res.content.id;
          } else {
            this.project_id = 0;
            this.companiesSelected = null;
            this.$utils.toastError(
              "Notificação",
              "Empresa sem projeto padrão definido"
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.isloadingCompany = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>