import HttpService from './HttpService'

const _http = new HttpService()
export default {
  start: (payload) => {
    return _http.post('/api/operators-appointment/start', payload)
  },
  finish: (payload) => {
    return _http.post('/api/operators-appointment/finish', payload)
  },
  valid: (payload) => {
    return _http.post('/api/operators-appointment/valid', payload)
  }  
}