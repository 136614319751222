<template>
  <b-overlay :variant="skin" :show="loading">
    <b-row>
      <b-col cols="12">
        <div class="chat-widget">
          <section v-if="data" class="chat-app-window">
            <!-- User Chat Area -->
            <vue-perfect-scrollbar
              ref="refChatLogPS"
              :settings="perfectScrollbarSettings"
              class="user-chats scroll-area"
              style="height: 232px"
            >
              <chat-log :chat-data="data" />
            </vue-perfect-scrollbar>
          </section>
        </div>
      </b-col>
    </b-row>
    <b-row class="mt-1">
      <b-col cols="12">
        <b-input-group>
          <b-form-input
            v-model="chatInputMessage"
            placeholder="Escreva aqui..."
            @keyup.enter="sendMessage"
          />
          <b-input-group-append>
            <b-button @click="sendMessage" variant="info">
              <feather-icon icon="ChevronRightIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import useAppConfig from "@core/app-config/useAppConfig";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import ChatLog from "@/views/apps/chat/ChatLog.vue";
import {
  BListGroup,
  BListGroupItem,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BButton,
} from "bootstrap-vue";

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BListGroup,
    BListGroupItem,
    BOverlay,
    VuePerfectScrollbar,
    ChatLog,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BButton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    data: {
      required: true,
    },
  },
  data() {
    return {
      chatInputMessage: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
    };
  },
  methods: {
    sendMessage() {
      this.$emit("sendMessage", this.chatInputMessage);
      this.chatInputMessage = "";
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/base/pages/app-chat-list.scss";
</style>
