<template>
  <div>
    <!-- MODAL DE OCORRENCIAS (NOVA OCORRENCIA) -->
    <b-modal
      ref="modal-add-ocorrencias"
      title="Adicionar Ocorrência"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <div class="row">
        <div class="col-12">
          <occurence-custom
            @submitted="onOcorrenciaSubmit"
            :teachers="teachers"
          />
        </div>
      </div>
    </b-modal>
    <!-- MODAL DE OCORRENCIAS (LISTAGEM) -->
    <b-modal
      ref="modal-ocorrencias"
      title="Ocorrências"
      hide-footer
      scrollable
      size="lg"
      no-close-on-backdrop
    >
      <b-row style="min-height: 200px">
        <b-col
          cols="12"
          v-if="
            entitys &&
            entitys.length > 0 &&
            occurrences &&
            occurrences.length > 0
          "
        >
          <b-col class="mt-1 mb-1" style="text-align: center">
            Ocorrência(s) cadastrada(s)
          </b-col>
          <b-tabs vertical pills>
            <b-tab
              v-for="entity in entitys"
              :key="entity.id"
              :title="entity.name"
            >
              <b-list-group>
                <template v-for="(occurrence, id) in occurrences">
                  <b-list-group-item
                    v-if="
                      occurrence.occurrenceCategory.occurrence_entity_id ==
                      entity.id
                    "
                    :key="id"
                  >
                    <p>
                      {{
                        occurrence.is_centraltech
                          ? "(Central Tech) " + occurrence.description
                          : occurrence.description
                      }}
                    </p>
                    <b-badge variant="info">
                      {{ occurrence.occurrenceCategory.name }}
                    </b-badge>
                    <p
                      @click="getOpenURLFileOccurence(occurrence.file_key)"
                      :class="['file-card', 'mt-50', `file-card-${skin}`]"
                      v-if="occurrence.file_key"
                    >
                      <feather-icon
                        size="20"
                        class="mr-50"
                        icon="FileTextIcon"
                      />
                      <span class="text-truncate">Arquivo Vinculado</span>
                    </p>
                  </b-list-group-item>
                </template>
              </b-list-group>
            </b-tab>
          </b-tabs>
        </b-col>
      </b-row>

      <b-row style="min-height: 200px">
        <b-col cols="12" v-if="incidents.length != 0">
          <b-col class="mt-1 mb-1" style="text-align: center">
            Ocorrência(s)
          </b-col>
          <b-list-group>
            <template v-for="(incident, id) in incidents">
              <b-list-group-item v-if="incident.description != ''" :key="id">
                <p>
                  {{ incident.description }}
                </p>
                <p v-if="incident.teacher_name">
                  Professor(a): {{ incident.teacher_name }}
                </p>
                <b-badge variant="info">
                  {{ incident.category }}
                </b-badge>
                <p class="file-occurrence-card mt-1" v-if="incident.file_name">
                  <feather-icon size="20" class="mr-50" icon="FileTextIcon" />
                  <span class="text-truncate">{{ incident.file_name }}</span>
                </p>
              </b-list-group-item>
            </template>
          </b-list-group>
        </b-col>
        <b-col
          v-if="!entitys && !occurrences && incidents.length == 0"
          class="mt-1"
          style="text-align: center"
        >
          Nenhuma ocorrência cadastrada
        </b-col>
      </b-row>
      <b-row style="margin-bottom: 8px; margin-top: 10px">
        <b-col style="text-align: left">
          <b-button variant="info" @click="newIncident(true)">
            <feather-icon icon="PlusIcon" class="mr-25" />
            <span>Ocorrência</span>
          </b-button>
        </b-col>
        <b-col style="text-align: right">
          <b-button @click="onOcorrenciaSave">Salvar</b-button>
        </b-col>
      </b-row>
    </b-modal>
    <!--FIM MODAL DE OCORRENCIAS-->

    <!-- MODAL DE PROFESSORES -->
    <b-modal
      size="sm"
      ref="modal-participants"
      title="Participantes"
      hide-footer
      scrollable
      no-close-on-backdrop
    >
      <b-card-text
        class="d-flex justify-content-start align-items-center"
        v-for="(participant, id) in participants"
        :key="id"
      >
        <b-avatar
          variant="secondary"
          class="mr-50"
          badge-top
          badge-offset="-5px"
          badge-variant="light"
        >
          <template v-if="participant.type == 'anchor'" #badge>
            <feather-icon icon="AnchorIcon" />
          </template>
        </b-avatar>
        <div class="ml-50 text-truncate">
          <b>{{ participant.name || "--" }}</b> <br />
          {{ participant.email || "--" }} <br />
          <b-badge
            class="mr-50"
            v-if="participant.phone && participant.phone.length > 5"
            variant="info"
          >
            {{ participant.phone }}
          </b-badge>
          <b-badge
            class="mr-50"
            v-if="participant.mobile && participant.mobile.length > 5"
            variant="info"
          >
            {{ participant.mobile }}
          </b-badge>
        </div>
      </b-card-text>
    </b-modal>

    <!-- MODAL DE CRONOGRAMA -->
    <b-modal
      size="lg"
      ref="modal-timeline"
      title="Cronograma"
      hide-footer
      scrollable
    >
      <b-overlay
        :variant="skin"
        :show="subevents.length == 0"
        rounded="sm"
        style="min-height: 100px"
      >
        <!-- SubEventos -->
        <b-row
          v-for="(subevent, i) in subevents"
          :key="i"
          :class="[
            'box-appointment-details',
            'p-1',
            `box-appointment-details-bg-${skin}`,
          ]"
          style="display: block"
        >
          <b-row>
            <b-col cols="4">
              <p class="font-weight-bolder">{{ subevent.name }}</p>
            </b-col>
            <b-col cols="4">
              <p class="font-weight-bolder">
                {{ subevent.start_date.split("T")[1].substring(0, 5) }} às
                {{ subevent.finish_date.split("T")[1].substring(0, 5) }}
              </p>
            </b-col>
            <b-col cols="4">
              <b-badge
                variant="transparent"
                class="ml-1"
                v-for="(
                  recordingmediapending, i
                ) in subevent.recordingMediaPendings"
                :key="i"
              >
                <b-button-group>
                  <b-button
                    :style="
                      recordingmediapending.recordingMediaSubjectPendings &&
                      recordingmediapending.recordingMediaSubjectPendings.length >
                        0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo.length > 0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo[0].media_archive
                        ? 'color: #28C76F'
                        : 'color: #f5912c'
                    "
                    size="sm"
                    :variant="
                      recordingmediapending.recordingMediaSubjectPendings &&
                      recordingmediapending.recordingMediaSubjectPendings.length >
                        0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo.length > 0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo[0].media_archive
                        ? 'outline-success'
                        : 'outline-warning'
                    "
                  >
                    Pendência #{{ recordingmediapending.id }}
                  </b-button>
                  <b-button
                    v-if="
                      recordingmediapending.recordingMediaSubjectPendings &&
                      recordingmediapending.recordingMediaSubjectPendings.length >
                        0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo.length > 0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo[0].media_file_url
                    "
                    size="sm"
                    variant="warning"
                    @click="
                      onClickDownloadVideo({
                        url_media:
                          recordingmediapending.recordingMediaSubjectPendings[0]
                            .mediaSubjectVideo[0].media_file_url,
                        original_filename:
                          recordingmediapending.recordingMediaSubjectPendings[0].mediaSubjectVideo[0].media_file_url
                            .split('?')[0]
                            .split('/')[4],
                      })
                    "
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                  <b-button
                    v-else-if="
                      recordingmediapending.recordingMediaSubjectPendings &&
                      recordingmediapending.recordingMediaSubjectPendings.length >
                        0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo.length > 0 &&
                      recordingmediapending.recordingMediaSubjectPendings[0]
                        .mediaSubjectVideo[0].media_archive
                    "
                    size="sm"
                    variant="success"
                    @click="
                      onClickDownloadVideo(
                        recordingmediapending.recordingMediaSubjectPendings[0]
                          .mediaSubjectVideo[0].media_archive[0]
                      )
                    "
                  >
                    <feather-icon icon="DownloadIcon" />
                  </b-button>
                </b-button-group>
              </b-badge>
            </b-col>
          </b-row>
          <b-row v-for="(studio, i) in subevent.studios" :key="i">
            <b-col cols="4" style="display: flex; align-items: baseline">
              <b-form-group
                v-if="studio.main"
                label="Estúdio Principal"
                class="font-weight-lighter"
              >
                <p class="font-weight-bolder">{{ studio.name }}</p>
              </b-form-group>
              <b-form-group
                v-else
                label="Estúdio de Apoio"
                class="font-weight-lighter"
              >
                <p class="font-weight-bolder">{{ studio.name }}</p>
              </b-form-group>
            </b-col>
            <b-col style="display: flex; align-items: baseline">
              <b-form-group label="Professores" class="font-weight-lighter">
                <div v-for="(teacher, i) in subevent.teachers" :key="i">
                  <p v-if="teacher.teacher_res" class="font-weight-bolder">
                    {{ teacher.teacher_name + " (Âncora)" }}
                  </p>
                  <p v-else class="font-weight-bolder">
                    {{ teacher.teacher_name }}
                  </p>
                </div>
              </b-form-group>
            </b-col>
            <b-col style="display: flex; align-items: baseline">
              <b-form-group label="Operador" class="font-weight-lighter">
                <p class="font-weight-bolder">{{ studio.operator_name }}</p>
              </b-form-group>
            </b-col>
          </b-row>
        </b-row>
      </b-overlay>
    </b-modal>

    <!-- BODY -->
    <b-row v-if="!viewMode">
      <b-col v-for="appointment in cards" :key="appointment.id" sm="12" md="6" lg="4">
        <Card
          :isCentralTech="isCentralTech"
          :companies="companies"
          :trasmissionTypes="trasmissionTypes"
          :appointment="appointment"
          :isOperatorPendency="isOperatorPendency"
          :currentOperator="currentOperator"
          @showParticipants="showParticipants"
          @showTimeline="showTimeline"
          @showOcurrences="showOcurrences"
          @materialUpload="materialUpload"
          @finish="createNextCard"
        />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col v-for="appointment in cards" :key="appointment.id" cols="12">
        <ShortCard
          :isCentralTech="isCentralTech"
          :companies="companies"
          :trasmissionTypes="trasmissionTypes"
          :appointment="appointment"
          :isOperatorPendency="isOperatorPendency"
          :currentOperator="currentOperator"
          @showParticipants="showParticipants"
          @showTimeline="showTimeline"
          @showOcurrences="showOcurrences"
          @materialUpload="materialUpload"
          @finish="createNextCard"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import _eventService from "@/services/event-service";
import _occurrenceService from "@/services/audiovisual/job-occurrence-service";
import _mediaFilesService from "@/services/audiovisual/media-files-service";
import { mapMutations, mapState } from "vuex";
import axios from "axios";
import { 
  BRow,
  BCol,
  BModal,
  BButtonGroup,
  BFormGroup,
  BListGroup,
  BListGroupItem
} from "bootstrap-vue";
import Card from "./Card.vue";
import ShortCard from "./ShortCard.vue";
import recordingOccurence from "../../recording/upload-teacher/components/Occurrence.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import { integer } from 'vee-validate/dist/rules';

export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BRow,
    BCol,
    Card,
    ShortCard,
    BModal,
    BButtonGroup,
    BFormGroup,
    BListGroup,
    BListGroupItem,
    "occurence-custom": recordingOccurence,
  },
  data() {
    return {
      cards: [],
      currentIndex: 0,
      subevents: [],
      participants: [],
      appointmentID: 0,
      occurrences: [],
      incidents: [],
      entitys: [],
      teachers: [],
      TYPE_MEDIA_FILE_OCCURRENCE: 3,
    }
  },
  props: {
    isOperatorPendency: {
      type: Boolean,
      required: false,
    },
    isCentralTech: {
      type: Boolean,
      required: false,
    },
    list: {
      type: Array,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
    trasmissionTypes: {
      type: Array,
      required: true,
    },
    currentOperator: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.createNextCard();
    this.$emit('created');
  },
  computed: {
    ...mapState({
      downloadItems: (state) => state.downloads.downloadItems,
      viewMode: (state) => state.toolbar.operation.viewListMode,
    }),
  },
  methods: {
    ...mapMutations({
      NEW_DOWNLOAD_ITEM: "downloads/NEW_DOWNLOAD_ITEM",
      ITEM_PROGRESS_UPDATE: "downloads/ITEM_PROGRESS_UPDATE",
      DEL_DOWNLOAD_ITEM: "downloads/DEL_DOWNLOAD_ITEM",
      SHOWING: "downloads/SHOWING",
    }),
    createNextCard() {
      if (
        this.list &&
        this.list.length > 0 &&
        this.currentIndex < this.list.length
      ) {
        this.cards.push(this.list[this.currentIndex]);
        this.currentIndex ++;
      } else {
        this.$emit('createNextCard')
      }
    },
    showTimeline(appointmentID) {
      this.subevents = [];
      if (appointmentID) {
        this.$refs["modal-timeline"].show();
        _eventService
          .showEventDetails(null, appointmentID)
          .then((res) => {
            if (res.content && Array.isArray(res.content)) {
              if (res.content.length > 0) {
                this.subevents = res.content[0].subevents;
              }
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
      }
    },
    showParticipants(participants) {
      this.participants = participants;
      this.$refs["modal-participants"].show();
    },
    onClickDownloadVideo(media_archive) {
      if (
        !this.downloadItems.some(
          (item) => item.url_media == media_archive.url_media
        )
      ) {
        this.NEW_DOWNLOAD_ITEM({
          url_media: media_archive.url_media,
          original_filename: media_archive.original_filename,
        });
        this.SHOWING(true);
      } else {
        this.$utils.toastWarning(
          "Por favor, aguarde",
          "O material solicitado já está sendo baixado"
        );
        return;
      }
      axios({
        method: "get",
        url: media_archive.url_media,
        responseType: "blob",
        onDownloadProgress: (progressEvent) => {
          this.ITEM_PROGRESS_UPDATE({
            url_media: progressEvent.target.responseURL,
            progress: Math.round(
              (progressEvent.loaded / progressEvent.total) * 100
            ),
          });
        },
      })
        .then((res) => {
          let blob = new Blob([res.data], { type: "video/mp4" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = media_archive.original_filename;
          link.click();
          this.DEL_DOWNLOAD_ITEM(media_archive.url_media);
        })
        .catch((error) => {
          this.DEL_DOWNLOAD_ITEM(media_archive.url_media);
          this.$utils.toastError("Notificação", error)
        })
        .finally(() => this.downloadCompleted());
    },
    downloadCompleted() {
      if (this.downloadItems.length == 0) {
        setTimeout(() => {
          this.SHOWING(false);
        }, 5000);
      }
    },
    materialUpload(operation) {
      if (!operation.studio_main) {
        this.$utils.toastError(
          "OPS!!!",
          "Estúdio de apoio não pode enviar material"
        );
        return;
      }

      this.$router.push({
        name: "audiovisual-recording-upload-operator",
        query: {
          teacher: operation.appointment.participants[0].id,
          appointment: operation.appointment_id,
        },
      });
    },
    showOcurrences(payload) {
      this.appointmentID = payload.appointmentID;
      this.teachers = payload.teachers;
      this.occurrences = [];
      this.incidents = [];
      this.entitys = [];
      if (this.appointmentID > 0) {
        _occurrenceService
          .findByAppointment(this.appointmentID, this.isCentralTech)
          .then((res) => {
            if (res.content && res.content.length > 0) {
              this.occurrences = res.content;
              this.occurrences.forEach((occurrence) => {
                let entityID =
                  occurrence.occurrenceCategory.occurrence_entity_id;
                if (!this.entitys.includes(entityID)) {
                  this.entitys.push(entityID);
                }
              });
              for (let index = 0; index < this.entitys.length; index++) {
                this.occurrences.forEach((occurrence) => {
                  if (
                    this.entitys[index] ==
                    occurrence.occurrenceCategory.occurrence_entity_id
                  ) {
                    this.entitys[index] = {
                      id: occurrence.occurrenceCategory.occurrence_entity_id,
                      name: occurrence.occurrenceCategory.occurrenceEntity.name,
                    };
                  }
                });
              }
            } else {
              this.$utils.toastWarning(
                "Notificação",
                "Nenhuma ocorrência encontrada."
              );
            }
            this.$refs["modal-ocorrencias"].show();
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      } else {
        this.$utils.toastError("Notificação", "Agendamento não encontrado.");
      }
    },
    newIncident(flag) {
      if (flag) {
        this.$refs["modal-add-ocorrencias"].show();
      } else {
        this.$refs["modal-add-ocorrencias"].hide();
      }
    },
    onOcorrenciaSubmit(_request) {
      const _payload = {
        ..._request,
        relation_key: this.appointmentID,
        data_key: 'appointments',
        is_centraltech: this.isCentralTech,
      };
      this.incidents.push(_payload);
      this.newIncident(false);
    },
    onOcorrenciaSave() {
      _occurrenceService
        .severalCreate({ data: this.incidents })
        .then((_res) => {
          this.$refs["modal-ocorrencias"].hide();
          this.$utils.toast(
            "Notificação",
            "Ocorrencias salvas e vinculadas com sucesso."
          );
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
    },
    getOpenURLFileOccurence(file_s3_key) {
      if (file_s3_key) {
        _mediaFilesService
          .getUrlFile(file_s3_key, this.TYPE_MEDIA_FILE_OCCURRENCE)
          .then((res) => {
            if (res.content) {
              window.open(res.content, "_blank");
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      }
    },
  }
};
</script>
<style scoped>
.box-appointment-details {
  border-radius: 15px;
  margin: 0px 0px 10px 0px !important;
}
.box-appointment-details-bg-light {
  background: #f6f6f6;
}
.box-appointment-details-bg-dark {
  background: #383e42;
}
.file-card {
  cursor: pointer;
  display: block;
  text-align: center;
  padding: 15px;
  background-color: #b7fad5;
  border-radius: 5px;
  max-width: 296px;
  margin: 2px;
}
.file-occurrence-card {
  display: block;
  padding: 5px;
  background-color: #b7fad5;
  border-radius: 5px;
  max-width: 296px;
  margin: 2px;
}
.file-card-dark {
  color: #656f7e;
}
</style>