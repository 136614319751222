<template>
  <div>
    <!-- MODAL DE PENDÊNCIAS DO OPERADOR  - Cards não finalizados -->
    <b-modal
      ref="modal-operator-pendencies"
      :title="`Pendências de Operação`"
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
    >
      <b-overlay :show="operatorPendenciesLoading" :variant="skin" rounded="sm">
        <Table
          :fields="operatorPendenciesFields"
          :list="operatorPendenciesList.itens"
          style="font-size: 9pt; text-align: center"
          border="simple"
          responsive
          hover
          @rowClick="setOperatorPendencyCard"
        />
      </b-overlay>
      <b-row class="align-items-center justify-content-center mt-2">
        <b-button
          variant="secondary"
          class="ml-3"
          @click="$root.$emit('clearPendency')"
        >
          Limpar Filtro
        </b-button>
      </b-row>
    </b-modal>
    <!-- finalizado com pendência - Retrabalho -->

    <b-modal
      ref="modal-operator-rework"
      title="Gravações"
      hide-footer
      centered
      size="xl"
      no-close-on-backdrop
    >
      <b-overlay :show="operatorReworkLoading" :variant="skin" rounded="sm">
        <Table
          :fields="operatorReworkFields"
          :list="operatorReworkList"
          style="font-size: 9pt; text-align: center"
          border="simple"
          responsive
          hover
          @rowClick="openRecord"
        />
      </b-overlay>
    </b-modal>

    <!-- MODAL DE NOVO AGENDAMENTO -->
    <b-modal
      ref="modal-appointment"
      :title="`Agendamento ${getDate()}`"
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
    >
      <div class="row">
        <div class="col-12">
          <FormAppointment @finish_app="newAppointmentFinish" />
        </div>
      </div>
    </b-modal>

    <!-- MODAL DE CHECKLIST -->
    <b-modal
      ref="modal-checklist"
      :title="`${checklist.name}`"
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
    >
      <div class="row">
        <div
          class="col-12"
          v-for="(steps, index) in checklist.listSteps"
          :key="index"
        >
          <span>
            {{ steps.step }}
          </span>
        </div>
        <br />
        <b-row style="margin-top: 10px" v-if="checklist.id > 0">
          <b-col cols="12" v-if="checklist.already_answered">
            <b-button variant="info" class="mr-1" disabled="true">
              Checklist Finalizado
            </b-button>
          </b-col>
          <b-col cols="12" v-else>
            <button--c
              :variant="'info'"
              :iconsize="'20'"
              :icon="null"
              :permission="'permission.appointment.operator.view'"
              :title="'Finalizar Checklist'"
              @clicked="save_answer(checklist)"
              class="mr-1"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>
    <div
      v-for="(workshift, index) in currentWorkshift
        ? workShifts.filter((f) => f.name == currentWorkshift.label)
        : workShifts"
      :key="index + keyConst"
    >
      <viewcard--c
        v-if="
          list.filter(
            (f) =>
              f.appointment_start.getHours() >= workshift.start.getHours() &&
              f.appointment_start.getHours() <
                (workshift.end.getHours() == 0 ? 24 : workshift.end.getHours())
          ).length > 0
        "
        :title="`Turno: ${workshift.title}`"
      >
        <div class="checklist-buttons" v-if="is_day_checklist && isCentralTech">
          <b-button
            class="mr-1"
            variant="dark"
            @click="onClickOpenChecklistCentralTech(workshift.id, 0)"
          >
            <span>Checklist de início</span>
          </b-button>
          <b-button
            variant="dark"
            @click="onClickOpenChecklistCentralTech(workshift.id, 2)"
          >
            <span>Checklist de fim</span>
          </b-button>
        </div>

        <!-- cards normais -->
        <TransferCard
          :isCentralTech="isCentralTech"
          :companies="companies"
          :trasmissionTypes="trasmissionTypes"
          :isOperatorPendency="isOperatorPendency"
          :currentOperator="currentOperator != null ? currentOperator.value : 0"
          :list="
            list.filter(
              (f) =>
                f.appointment_start.getHours() >= workshift.start.getHours() &&
                f.appointment_start.getHours() <
                  (workshift.end.getHours() == 0
                    ? 24
                    : workshift.end.getHours())
            )
          "
          class="px-1"
          @createNextCard="() => transferCount++"
          @created="() => workshiftCount++"
        />
      </viewcard--c>
    </div>

    <p
      v-if="list.length == 0"
      class="text-center mt-4"
    >
      Não há demandas de operação atribuídas para esse período
    </p>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BImg,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BCardBody,
  BMedia,
  BFormDatepicker,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroupPrepend,
  BButton,
  BFormTags,
  BFormTag,
  BFormGroup,
  BOverlay,
} from "bootstrap-vue";
import Table from "@/components/Table.vue";
import useAppConfig from "@core/app-config/useAppConfig";
import _recordingService from "@/services/audiovisual/recording-media";
import _appointmentService from "@/services/appointment-service";
import _operatorsAppointmentService from "@/services/operators-appointment-service";
import _operatorsAppointmentHistoryService from "@/services/operators-appointment-history-service";
import _operatorsService from "@/services/operators-service";
import _occurrenceService from "@/services/audiovisual/job-occurrence-service";
import _workShiftsService from "@/services/audiovisual/work-shift-service";
import _checkListService from "@/services/audiovisual/check-list-service";
import _operatorService from "@/services/appointment-studios-operator";
import _authService from "@/services/auth-service";
import TransferCard from "./components/TransferCards.vue";
import FormAppointment from "./components/FormAppointment.vue";
import { mapMutations, mapState } from "vuex";
import _checkListOperatorAnswerService from "@/services/audiovisual/check-list-operator-answer-service";

import _companyService from "@/services/company-service";
import _transmissionTypeService from "@/services/audiovisual/transmission-type-service";
import { faBullseye } from "@fortawesome/free-solid-svg-icons";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BImg,
    BCardTitle,
    BListGroup,
    BListGroupItem,
    BCardBody,
    BMedia,
    BFormDatepicker,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroupPrepend,
    BButton,
    BFormTags,
    BFormTag,
    BFormGroup,
    BOverlay,
    TransferCard,
    FormAppointment,
    Table,
  },
  data() {
    return {
      operatorID: null,
      isCentralTech: false,
      list: [],
      autoReload: null,
      workShifts: [],
      checklist: {
        name: "",
        id: 0,
        listSteps: [],
      },
      is_day_checklist: true,
      companies: [],
      trasmissionTypes: [],
      transferCount: 0,
      workshiftCount: 0,
      operatorPendenciesLoading: false,
      operatorPendencies: [],
      isOperatorPendency: false,
      keyConst: 10,
      operatorPendenciesList: {
        page: 0,
        itens: []
      },
      operatorPendenciesFields: [
        { key: "appointment_id", label: "Agendamento" },
        { key: "appointment_start_formatted", label: "Início do agendamento" },
        { key: "operators_appointment_status", label: "Status"}
      ],
      operatorReworkLoading: false,
      operatorReworkList: [],
      operatorReworkFields: [
        { key: "id", label: "ID" },
        { key: "created_at", label: "Criação" },
        { key: "event_name", label: "Evento/Curso" },
        { key: "project_name", label: "Projeto" },
        { key: "teacher_name", label: "Professor" },
        { key: "company_name", label: "Empresa" },
        { key: "last_description", label: "Comentário Editor" },
      ],
    };
  },
  watch: {
    transferCount(newValue, _oldValue) {
      if (this.workshiftCount > 0 && newValue == this.workshiftCount && !this.isOperatorPendency) {
        this.setAutoReload();
      }
    },
    operatorPendencies: {
      handler(newValue, _oldValue) {
        if(newValue.length > 0) {
          this.list = newValue;
          this.keyConst++
        } else {
          this.list = [];
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapState({
      currentDate: (state) => state.toolbar.operation.dateSelected,
      currentOperator: (state) => state.toolbar.operation.operatorSelected,
      currentWorkshift: (state) => state.toolbar.operation.workshiftSelected,
    }),
  },
  destroyed() {
    this.UPDATE_CURRENT_TOOLBAR("none");
  },
  created() {
    this.getAuthUser();
    this.getWorkShifts();
    this.getCompanies();
    this.getTrasmissionTypes();
  },
  mounted() {
    this.$root.$on("operatorFilter", () => {
      this.transferCount = 0;
      this.workshiftCount = 0;
      this.list = [];
      this.setAutoReload();
    });
    this.$root.$on("newAppointment", () => {
      this.$refs["modal-appointment"].show();
    });
    this.$root.$on("listOperatorPendencies", () => {
      this.getOperatorPendencies();
    });
    this.$root.$on("listOperatorReworks", () => {
      this.getOperatorReworks();
    });
    this.$root.$on("clearPendency", () => {
      this.isOperatorPendency = false;
      this.operatorPendencies = [];
      this.$refs["modal-operator-pendencies"].hide();
      this.setAutoReload();
    });
  },
  beforeRouteLeave(to, from, next) {
    if (this.autoReload) {
      clearInterval(this.autoReload);
      next();
    } else {
      next();
    }
  },
  methods: {
    ...mapMutations({
      UPDATE_CURRENT_TOOLBAR: "toolbar/UPDATE_CURRENT_TOOLBAR",
      UPDATE_OPERATOR_SELECTED: "toolbar/operation/UPDATE_OPERATOR_SELECTED",
      UPDATE_COUNT_REWORKS: "toolbar/operation/UPDATE_COUNT_REWORKS",
      UPDATE_COUNT_PENDENCIES: "toolbar/operation/UPDATE_COUNT_PENDENCIES",
    }),
    getCompanies() {
      _companyService
        .showAll()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.companies = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getTrasmissionTypes() {
      _transmissionTypeService
        .showProductsAll()
        .then((res) => {
          if (res.content && res.content.length > 0) {
            this.trasmissionTypes = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getAuthUser() {
      this.loading = true;
      _authService
        .getAuthUser()
        .then((res) => {
          if (res.content && res.content.type) {
            this.operatorID = res.content.operator_id;
            if (res.content.type == "operator_audiovisual") {
              this.UPDATE_OPERATOR_SELECTED({
                label: res.content.name,
                value: this.operatorID,
              });
              this.UPDATE_CURRENT_TOOLBAR("operator-bar");
            } else if (
              res.content.permission_groups.some(
                (permission) => permission == "Admin"
              ) ||
              res.content.type == "central_tech"
            ) {
              this.UPDATE_CURRENT_TOOLBAR("operation");
              this.isCentralTech = true;
            } else {
              this.$router.go(-1);
            }
          }
          this.setAutoReload();
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getRecords() {
      _operatorService
        .operations(
          this.currentDate,
          this.currentOperator ? this.currentOperator.value : null
        )
        .then((res) => {
          if (res.content) {
            this.list = res.content.map((m) => {
              return {
                id: m.id,
                appointment_start: new Date(m.appointment_start),
              };
            });
            this.is_day_checklist = this.is_checklist_day();
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    checkOperatorReworks() {
      if (this.currentOperator) {
        _recordingService
          .getRecordingReworkByOperator(this.currentOperator.value)
          .then((res) => {
            if (res.content) {
              if(Array.isArray(res.content)) {
                this.UPDATE_COUNT_REWORKS(res.content.length);
              }
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      } else {
        this.UPDATE_COUNT_REWORKS(0);
      }
    },
    getOperatorReworks() {
      if (this.currentOperator) {
        this.operatorReworkLoading = true;
        this.operatorReworkList = [];
        _recordingService
          .getRecordingReworkByOperator(this.currentOperator.value)
          .then((res) => {
            if (res.content) {
              this.operatorReworkList = res.content.map((m) => {
                return {
                  id: m.id,
                  created_at: m.created_at
                    .split("T")[0]
                    .split("-")
                    .reverse()
                    .join("/"),
                  event_name: m.event_name,
                  project_name: m.project_name,
                  teacher_name: m.teacher_name,
                  company_name: this.companies.filter(
                    (f) => f.id == m.company_id
                  )[0].name,
                  last_description: m.editionJobsDTO && m.editionJobsDTO.lastDescription ? m.editionJobsDTO.lastDescription : "--"
                };
              });
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.operatorReworkLoading = false));
        this.$refs["modal-operator-rework"].show();
      }
    },
    openRecord(record) {
      this.$router.push({
        path: `/audiovisual/recording/edit/${record.id}`,
      });
    },
    checkOperatorPendencies() {
      if (this.currentOperator) {
        _operatorService
          .operations(this.currentDate, this.currentOperator.value, true)
          .then((res) => {
            if (res.content) {
              if (Array.isArray(res.content)) {
                this.UPDATE_COUNT_PENDENCIES(res.content.length);
              }
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
      } else {
        this.UPDATE_COUNT_PENDENCIES(0);
      }
    },
    getOperatorPendencies() {
      this.operatorPendenciesLoading = true;
      this.operatorPendenciesList = {
        page: 0,
        itens: []
      };
      if (this.currentOperator) {
        _operatorService
          .operations(this.currentDate, this.currentOperator.value, true)
          .then((res) => {
            if (res.content) {
              console.log("Pendencia => ", res.content);
              if (Array.isArray(res.content)) {
                this.operatorPendenciesList = {
                  page: 0,
                  itens: res.content.map((m) => {
                    return {
                      id: m.id,
                      appointment_start: new Date(m.appointment_start),
                      appointment_start_formatted: m.appointment_start
                        .split("T")[0]
                        .split("-")
                        .reverse()
                        .join("/"),
                      appointment_id: m.appointment_id,
                      operators_appointment_status: m.operators_appointment_status
                    };
                  }),
                };
              }
              this.is_day_checklist = this.is_checklist_day();
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.operatorPendenciesLoading = false));
        this.$refs["modal-operator-pendencies"].show();
      }
    },
    setOperatorPendencyCard(record, _) {
      this.isOperatorPendency = true
      if (this.operatorPendencies.length == 0) {
        if (this.autoReload) {
          clearInterval(this.autoReload);
        }
      }
      if(record && !this.operatorPendencies.some(p => p.id == record.id)) {
        this.operatorPendencies.push(record);
      }
      this.$refs["modal-operator-pendencies"].hide();
    },
    setAutoReload() {
      if (this.autoReload) {
        clearInterval(this.autoReload);
      }
      this.getRecords();
      this.checkOperatorReworks();
      this.checkOperatorPendencies();

      this.autoReload = setInterval(() => {
        this.getRecords();
        this.checkOperatorReworks();
        this.checkOperatorPendencies();
      }, 15000);
    },
    onClickOpenChecklistCentralTech(_workshift, _type) {
      this.loading = true;

      let params = {
        workshift_id: _workshift,
        type: _type,
      };
      this.checklist = {};
      _checkListService
        .findChecklistCT(params)
        .then((res) => {
          this.checklist = res.content;
          this.checklist.workshift_id = _workshift;
          this.checklist.operator_id = this.operatorID;
          this.$refs["modal-checklist"].show();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    getDate() {
      const today = new Date();
      return (
        (today.getDate() < 10 ? "0" + today.getDate() : today.getDate()) +
        "/" +
        (today.getMonth() + 1 < 10
          ? "0" + (today.getMonth() + 1)
          : today.getMonth() + 1) +
        "/" +
        today.getFullYear()
      );
    },
    newAppointmentFinish() {
      this.$refs["modal-appointment"].hide();
      this.setAutoReload();
    },
    getWorkShifts() {
      _workShiftsService
        .showAll()
        .then((res) => {
          this.workShifts = res.content.map((m) => {
            let start = new Date(m.start);
            let end = new Date(m.end);
            return {
              id: m.id,
              name: m.name,
              title:
                m.name +
                " - Início entre " +
                start.getHours() +
                "h e " +
                end.getHours() +
                "h",
              start: start,
              end: end,
            };
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    save_answer(checklist) {
      this.loading = true;
      let entity = {
        data: {
          operator_id: this.operatorID,
          checklist_id: checklist.id,
          workshift_id: checklist.workshift_id,
          appointment_studios_operator_id:
            checklist.appointment_studios_operator_id,
        },
      };
      _checkListOperatorAnswerService
        .save(entity)
        .then(() => {
          this.$refs["modal-checklist"].hide();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    is_checklist_day() {
      let date_now = this.getDate();
      let date_split = date_now.split("/");
      let date_now_valid = `${date_split[2]}-${date_split[1]}-${date_split[0]}`;
      return this.currentDate == date_now_valid;
    },
  },
};
</script>

<style scoped>
.checklist-buttons {
  position: absolute;
  top: 20px;
  right: 20px;
}
</style>
