import HttpService from "./HttpService";
const _http = new HttpService();

export default {
  operations: (appointment_start, operator_id = null, select_pendencies = false) => {
    let _params = `?appointment_start=${appointment_start}`;
    if (operator_id) {
      _params += `&operator_id=${operator_id}`;
    }
    if (select_pendencies) {
      _params += `&select_pendencies=${select_pendencies}`;
    }
    return _http.get(`/api/appointment-studios-operator/app-st-by-operator${_params}`);
  },
  operationDetails: (id) => {
    return _http.get(`/api/appointment-studios-operator/detail-by-id/${id}`);
  },
  operationStatus: (id) => {
    return _http.get(`/api/appointment-studios-operator/status?appointment_studios_operator_id=${id}`);
  },
  ignoreUpload: (id, payload) => {
    return _http.patch(`/api/appointment-studios-operator?id=${id}`, payload);
  },
  changeOperator: (id, payload) => {
    return _http.patch(`/api/appointment-studios-operator?id=${id}`, payload);
  },
  operationPlay: (payload) => {
    return _http.post('/api/appointment-studios-operator/start', payload)
  },
  operationFinish: (payload) => {
    return _http.post('/api/appointment-studios-operator/finish', payload)
  },
};
