<!-- AVISO
Se for realizar alguma modificação aqui, pode ser necessário realizar
a mesma alteração no componente 'Card.vue'
-->
<template>
  <b-overlay :variant="skin" :show="isloading" rounded="sm">
    <!-- MODAL DE CANCELAR OPERAÇÃO -->
    <b-modal
      ref="modal-cancel-operation"
      title="Cancelar Operação"
      hide-footer
      centered
    >
      <div style="min-width: 200px">
        <b-form-textarea
          v-model="cancelTxt"
          :state="cancelTxt.length > 5"
          placeholder="Breve Descrição..."
          rows="3"
        />
      </div>

      <div class="d-flex justify-content-end m-0 p-0">
        <b-button
          variant="danger"
          class="mt-50"
          style="min-width: 100px"
          @click="appointmentCancel"
          :disabled="cancelTxt.length <= 5"
        >
          <b-spinner v-if="cancelLoading" small label="Small Spinner" />
          <span v-else>Continuar</span>
        </b-button>
      </div>
    </b-modal>

    <!-- MODAL DE AVISOS -->
    <b-modal ref="modal-chat" size="lg" title="Avisos" hide-footer centered>
      <Chat
        class="mb-1"
        :loading="isloadingChat"
        :data="chatData"
        @sendMessage="sendMessage"
      />
    </b-modal>

    <!-- MODAL DE TRANSMISSÕES -->
    <b-modal
      ref="modal-trasmission"
      size="lg"
      title="Transmissões"
      hide-footer
      centered
    >
      <b-overlay :variant="skin" :show="isloadingTrasmission">
        <b-row class="mb-1">
          <b-col cols="12">
            <div class="chat-widget">
              <section class="chat-app-window">
                <!-- Scroll Area -->
                <vue-perfect-scrollbar
                  ref="refChatLogPS"
                  :settings="perfectScrollbarSettings"
                  class="user-chats scroll-area"
                  style="height: 284px"
                >
                  <div
                    v-if="operation && operation.appointment && operation.links"
                  >
                    <div
                      v-for="link in operation.links"
                      :key="link.id"
                      :class="[
                        'link-card',
                        'text-left',
                        `link-card-bg-${skin}`,
                      ]"
                    >
                      <h4>
                        {{ operation.appointment.jobtype_name || "--" }}
                      </h4>
                      <div class="d-flex justify-content-between">
                        <div style="width: 50%">
                          <span style="font-size: 9pt"> Canal: </span>
                          <br />
                          <b>
                            {{ link.transmission_type_name || "--" }}
                          </b>
                        </div>
                        <div style="width: 50%">
                          <span style="font-size: 9pt"> Empresa: </span>
                          <br />
                          <b> {{ link.company_name || "--" }} </b>
                        </div>
                      </div>
                      <div class="d-flex mt-50">
                        <div>
                          <span style="font-size: 9pt">
                            Titulo da transmissão:
                          </span>
                          <br />
                          <b class="transmission">
                            {{ link.youtube_title || "--" }}
                          </b>
                        </div>
                        <feather-icon
                          v-if="link.youtube_title"
                          @click="clipboardCopy(link.youtube_title)"
                          size="16"
                          icon="CopyIcon"
                          class="mt-2 ml-50 cursor-pointer"
                          v-b-tooltip.hover.top="'Copiar!'"
                        />
                      </div>
                      <div class="d-flex mt-50">
                        <div class="main-transmission">
                          <span style="font-size: 9pt">
                            Link da transmissão:
                          </span>
                          <br />
                          <b class="transmission">
                            {{ link.youtube_url || "--" }}
                          </b>
                        </div>
                        <feather-icon
                          v-if="link.youtube_url"
                          @click="clipboardCopy(link.youtube_url)"
                          size="16"
                          icon="CopyIcon"
                          class="mt-2 ml-50 cursor-pointer"
                          v-b-tooltip.hover.top="'Copiar!'"
                        />
                      </div>
                      <div class="d-flex mt-50">
                        <div class="main-transmission">
                          <span style="font-size: 9pt">
                            Chave da transmissão:
                          </span>
                          <br />
                          <b class="transmission"> {{ "--" }} </b>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p v-else class="mt-2">Sem links de transmissão</p>
                </vue-perfect-scrollbar>
              </section>
            </div>
          </b-col>
        </b-row>
      </b-overlay>
    </b-modal>

    <!-- MODAL DE CHECKLIST CARD-->
    <b-modal
      ref="modal-checklist-card"
      :title="`${checklist.name}`"
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
    >
      <div class="row">
        <div
          class="col-12"
          v-for="(steps, index) in checklist.listSteps"
          :key="index"
        >
          <span>
            {{ steps.step }}
          </span>
        </div>
        <br />
        <b-row style="margin-top: 10px" v-if="checklist.id > 0">
          <b-col cols="12" v-if="checklist.already_answered">
            <b-button variant="info" class="mr-1" disabled="true">
              Checklist Finalizado
            </b-button>
          </b-col>
          <b-col cols="12" v-else>
            <button--c
              :variant="'info'"
              :iconsize="'20'"
              :icon="null"
              :permission="'permission.appointment.operator.view'"
              :title="'Finalizar Checklist'"
              @clicked="saveAnswerCard(checklist)"
              class="mr-1"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- MODAL DE CHECKLIST ALERTA  CARD-->
    <b-modal
      ref="modal-checklist-alert-card"
      title="Alerta"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <div class="pb-25 row">
        <div class="mb-3 col-12">
          O CheckList de Execução <b>não foi preenchido,</b> deseja continuar
          assim mesmo?
        </div>
        <b-col style="text-align: left">
          <!--CONTINUAR 2 -->
          <b-button @click="onClickOpenChecklist(appointmentSelected, 2)"
            >CONTINUAR</b-button
          >
        </b-col>
        <b-col style="text-align: right">
          <!--PREENCHER 1 -->
          <b-button @click="onClickOpenChecklist(appointmentSelected, 1)"
            >PREENCHER</b-button
          >
        </b-col>
      </div>
    </b-modal>

    <!-- MODAL TROCA DE OPERADOR -->
    <b-modal
      ref="modal-change-operator"
      title="Deseja trocar operador ?"
      hide-footer
      size="lg"
      centered
      @hide="onHideModal"
      cancel-title="Fechar"
    >
      <div class="row">
        <div class="col-12">
          <v-select
            v-model="operatorsSelected"
            :options="operators"
            autocomplete="off"
            :clearable="false"
            @search="getOperators"
            searchable
            :loading="isLoadingOperator"
          />
        </div>
      </div>

      <b-row style="margin-bottom: 8px; margin-top: 50px">
        <b-col style="text-align: right">
          <b-button @click="onClickChangeOperatorSave(operation)"
            >Salvar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <!-- CARD BODY -->
    <div
      :class="[
        'shortcard',
        'mb-1',
        `shortcard-${getclass(operation)}`,
        isOperatorPendency ? 'shortcard-pending' : '',
      ]"
    >
      <b-row v-if="operation && operation.appointment">
        <b-col cols="9">
          <b-row>
            <b-col cols="2" class="text-truncate">
              <b>{{ operation.company_name || "--" }}</b>
            </b-col>
            <b-col class="text-truncate">
              Estúdio: <b>{{ operation.studio_name || "--" }}</b>
            </b-col>
            <b-col class="text-truncate">
              Evento: <b>{{ operation.event_course || "--" }}</b>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="2">
              <b>{{ operation.id }}</b>
            </b-col>
            <b-col class="text-truncate">
              Operador: <b>{{ operation.operator_name || "--" }} </b>
              <feather-icon
                v-if="operation.operator_backup_name"
                size="15"
                class="ml-25"
                style="margin-bottom: 3px"
                v-b-tooltip.hover.top.html="
                  `OPERADOR BACKUP <br /> ${operation.operator_backup_name}`
                "
                icon="UsersIcon"
              />
            </b-col>
            <b-col>
              <b-link
                class="underline"
                :to="`/pedagogic/calendar?q=${operation.appointment_id}`"
                ><b>#{{ operation.appointment_id }}</b></b-link
              >
              <b>
                - {{ operation.appointment.jobtype_name || "--" }} -
                {{ operation.appointment.start_time || "--" }} até
                {{ operation.appointment.end_time || "--" }}
              </b>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="ml-auto" cols="auto">
          <!-- GRAVAÇÂO ASSOCIADA -->
          <b-button
            :disabled="
              !(
                operation &&
                operation.recording_media_id &&
                !operation.recording_media_excluded
              )
            "
            class="btn-icon m-25"
            variant="primary"
            v-b-tooltip.hover.top="`Gravação #${operation.recording_media_id}`"
            :to="`/audiovisual/recording/view/${operation.recording_media_id}`"
          >
            <feather-icon size="16" icon="FilmIcon" />
          </b-button>

          <!-- CRONOGRAMA -->
          <b-button
            :disabled="!(operation && operation.event_id)"
            class="btn-icon m-25"
            variant="primary"
            v-b-tooltip.hover.top="'Cronograma'"
            @click="$emit('showTimeline', operation.appointment_id)"
          >
            <feather-icon size="16" icon="ClockIcon" />
          </b-button>

          <!-- TRANSMISSÕES -->
          <b-button
            class="btn-icon m-25"
            variant="primary"
            v-b-tooltip.hover.top="'Transmissões'"
            @click="getTrasmission"
          >
            <feather-icon size="16" icon="YoutubeIcon" />
          </b-button>

          <!-- AVISOS -->
          <b-button
            class="btn-icon m-25"
            variant="primary"
            v-b-tooltip.hover.top="'Avisos'"
            @click="getChat"
          >
            <feather-icon size="16" icon="MessageSquareIcon" />
          </b-button>

          <!-- PROFESSORES -->
          <b-button
            class="btn-icon m-25"
            variant="primary"
            v-b-tooltip.hover.top="'Professores'"
            @click="
              $emit('showParticipants', operation.appointment.participants)
            "
          >
            <feather-icon size="16" icon="UsersIcon" />
          </b-button>

          <!-- BOTÕES DE AÇÃO -->
          <b-button
            :id="`action-button-${operation.id}`"
            class="btn-icon ml-1"
            variant="info"
            v-b-tooltip.hover.top="'Ações'"
            v-if="
              isCentralTech ||
              (currentOperator > 0 &&
                operation &&
                currentOperator == operation.operator_id)
            "
          >
            <feather-icon size="16" icon="MoreVerticalIcon" />
          </b-button>
          <b-popover
            id="small-popover"
            :target="`action-button-${operation.id}`"
            variant="info"
            triggers="focus"
            placement="bottom"
          >
            <!-- UPLOAD DE MATERIAL -->
            <b-button
              v-if="
                operation &&
                operation.operators_appointment_status &&
                operation.operators_appointment_status == 'Iniciado' &&
                operation.required_upload_material &&
                !operation.ignore_upload
              "
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="
                operation.studio_main
                  ? 'Enviar Material'
                  : 'Estúdio de apoio não pode enviar material'
              "
              @click="$emit('materialUpload', operation)"
              :disabled="!operation.studio_main"
            >
              <feather-icon size="16" icon="UploadIcon" />
            </b-button>

            <!-- OBRIGATORIEDADE DE UPLOAD -->
            <b-button
              v-if="
                operation &&
                operation.required_upload_material &&
                operation.operators_appointment_status &&
                operation.operators_appointment_status != 'Finalizado' &&
                isCentralTech &&
                operation.studio_main
              "
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="'Obrigatoriedade de Upload'"
              @click="controlUnlockUpload()"
            >
              <feather-icon
                size="16"
                :icon="operation.ignore_upload ? 'UnlockIcon' : 'LockIcon'"
              />
            </b-button>

            <!-- PLAY -->
            <b-button
              v-if="
                operation &&
                operation.operators_appointment_status &&
                operation.operators_appointment_status ==
                  'Pendente de inicialização'
              "
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="'Assumir Demanda'"
              @click="onClickOpenChecklist(appointment, 0)"
            >
              <feather-icon size="16" icon="PlayIcon" />
            </b-button>

            <!-- FINALIZAR DEMANDA -->
            <b-button
              v-if="
                operation &&
                operation.operators_appointment_status &&
                operation.operators_appointment_status == 'Iniciado'
              "
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="'Finalizar Demanda'"
              @click="
                operation.checklist_execution_finalized
                  ? controlChecklistFinalized(appointment, 2, operation)
                  : onFinishNoExecution(operation, 1)
              "
            >
              <feather-icon size="16" icon="StopCircleIcon" />
            </b-button>

            <!-- OCORRENCIAS -->
            <b-button
              v-if="operation && operation.operators_appointment_status"
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="'Ver Ocorrências'"
              @click="
                $emit('showOcurrences', {
                  appointmentID: operation.appointment_id,
                  teachers,
                })
              "
            >
              <feather-icon size="16" icon="FileTextIcon" />
            </b-button>

            <!-- CANCELAR OPERAÇÃO -->
            <b-button
              v-if="
                operation &&
                operation.operators_appointment_status &&
                operation.operators_appointment_status != 'Finalizado' &&
                isCentralTech
              "
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="
                operation.studio_main
                  ? 'Cancelar Operação'
                  : 'Estúdio de apoio não pode cancelar operação'
              "
              :disabled="!operation.studio_main"
              @click="() => $refs['modal-cancel-operation'].show()"
            >
              <feather-icon size="16" icon="XIcon" />
            </b-button>

            <!-- CHECKLIST DE EXECUÇÃO -->
            <b-button
              v-if="
                operation &&
                operation.operators_appointment_status &&
                operation.operators_appointment_status == 'Iniciado'
              "
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="'CheckList Execução'"
              @click="onClickOpenChecklist(appointment, 1)"
            >
              <feather-icon size="16" icon="CheckCircleIcon" />
            </b-button>

            <!-- TROCAR OPERADOR -->
            <b-button
              v-if="
                operation &&
                operation.operators_appointment_status &&
                operation.operators_appointment_status != 'Finalizado' &&
                isCentralTech
              "
              class="btn-icon m-25"
              variant="light"
              v-b-tooltip.hover.top="'Trocar Operador'"
              @click="onClickChangeOperator(operation)"
            >
              <feather-icon size="16" icon="UserIcon" />
            </b-button>
          </b-popover>
        </b-col>
      </b-row>
      <p v-else class="text-center">Carregando...</p>
    </div>
  </b-overlay>
</template>

<script>
import _appointmentService from "@/services/appointment-service";
import _operatorService from "@/services/appointment-studios-operator";
import _operatorsService from "@/services/operators-service";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Chat from "./Chat.vue";
import ChatLog from "@/views/apps/chat/ChatLog.vue";
import formAppointment from "./FormAppointment.vue";
import _checkListService from "@/services/audiovisual/check-list-service";
import _checkListOperatorAnswerService from "@/services/audiovisual/check-list-operator-answer-service";
import _recordingMediaService from "@/services/audiovisual/recording-media";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  BCardBody,
  BCard,
  BCardText,
  BLink,
  BImg,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BTooltip,
  BTabs,
  BTab,
  BAvatar,
  VBPopover,
  BPopover,
  BFormTextarea,
  BSpinner,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BButtonGroup,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BCardBody,
    BCard,
    BCardText,
    BLink,
    BImg,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BTooltip,
    BTabs,
    BTab,
    BAvatar,
    BPopover,
    BFormTextarea,
    BSpinner,
    BOverlay,
    VuePerfectScrollbar,
    ChatLog,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BButtonGroup,
    VBTooltip,
    formAppointment,
    Chat,
    BBadge,
  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
  },
  props: {
    isOperatorPendency: {
      type: Boolean,
      required: false,
    },
    isCentralTech: {
      type: Boolean,
      required: false,
    },
    appointment: {
      type: Object,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
    trasmissionTypes: {
      type: Array,
      required: true,
    },
    currentOperator: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.getRecords();
  },
  data() {
    return {
      isloading: false,
      isloadingChat: false,
      isloadingTrasmission: false,
      isLoadingOperator: false,
      chatData: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      operation: null,
      cancelTxt: "",
      cancelLoading: false,
      weekdays: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],
      checklist: {
        name: "",
        id: 0,
        listSteps: [],
      },
      appointmentSelected: null,
      operators: [],
      operatorsSelected: [{ label: "Selecione", value: "-1" }],
      teachers: [],
    };
  },
  methods: {
    onHideModal(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    onClickChangeOperator() {
      // CORE
      this.$refs["modal-change-operator"].show();
    },
    getOperators(_text) {
      // CORE
      if (_text && _text.length >= 3 && !this.isloadingStudio) {
        let filter = {
          search: _text,
          ascOrDes: true,
          orderByKey: "name",
        };
        setTimeout(() => {
          this.isLoadingOperator = true;
          _operatorsService
            .show(1, filter)
            .then((res) => {
              if (res.content) {
                this.operators = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              }
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.isLoadingOperator = false));
        }, 500);
      }
    },
    onClickChangeOperatorSave(_operation) {
      // CORE
      if (this.operatorsSelected && this.operatorsSelected.value !== "-1") {
        let payload = [
          {
            path: "/operator_id",
            op: "replace",
            value: this.operatorsSelected.value,
          },
        ];
        this.$refs["modal-change-operator"].hide();
        this.isloading = true;
        _operatorService
          .changeOperator(_operation.id, payload)
          .then((res) => {
            if (res.content) {
              this.getRecords();
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      } else {
        this.$utils.toastError("Operador");
      }
    },
    teachersToArray(content) {
      // CORE
      if (content && content.teachers) {
        this.teachers = this.populardropdown(
          content.teachers,
          "teacher_name",
          "teacher_id",
          "teacher_email"
        );
      }
    },
    getRecords() {
      // CORE
      this.isloading = true;
      _operatorService
        .operationDetails(this.appointment.id)
        .then((res) => {
          if (res.content) {
            this.operation = res.content;
            this.teachersToArray(res.content);
            this.getAppointmentDetails();
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getAppointmentDetails() {
      // CORE
      _appointmentService
        .find_short(this.operation.appointment_id)
        .then((res) => {
          if (res.content) {
            this.operation.appointment = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.isloading = false;
          this.$emit("finish");
        });
    },
    getChat() {
      // CORE
      this.isloadingChat = true;
      this.$refs["modal-chat"].show();
      this.chatData = {
        chat: {
          id: 0,
          chat: [],
        },
        contact: {
          id: 0,
        },
      };
      this.chatData.chat.id = this.operation.appointment_id;
      _appointmentService
        .getChat(this.operation.appointment_id)
        .then((res) => {
          this.chatData.contact.id = res.content.user_id;
          res.content.chats.forEach((chat) => {
            this.chatData.chat.chat.push({
              message: chat.message,
              time: chat.created_at,
              senderId: chat.user_id,
              userName: chat.user_name,
              userNameInitial: this.getinitials(chat.user_name),
            });
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloadingChat = false));
    },
    sendMessage(inputMessage) {
      //CORE
      if (inputMessage) {
        this.isloadingChat = true;
        const payload = {
          data: {
            message: inputMessage,
            appointment_id: this.operation.appointment_id,
          },
        };
        _appointmentService
          .createChat(payload)
          .then((res) => {
            this.chatData.chat.chat.push({
              message: inputMessage,
              time: new Date(),
              senderId: this.chatData.contact.id,
              userName: res.content,
              userNameInitial: this.getinitials(res.content),
            });
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloadingChat = false));
      }
    },
    getTrasmission() {
      // CORE
      this.isloadingTrasmission = true;
      this.$refs["modal-trasmission"].show();
      _appointmentService
        .find_short_links(this.operation.appointment_id)
        .then((res) => {
          if (res.content && res.content.length > 0) {
            res.content.forEach((link) => {
              if (this.companies.some((comp) => comp.id == link.company_id)) {
                let company = this.companies.filter(
                  (comp) => comp.id == link.company_id
                );
                link.company_name = company[0].name;
              }
              if (
                this.trasmissionTypes.some(
                  (trans) => trans.id == link.transmission_type_id
                )
              ) {
                let transmission = this.trasmissionTypes.filter(
                  (comp) => comp.id == link.transmission_type_id
                );
                link.transmission_type_name = transmission[0].name;
              }
            });
            this.operation.links = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloadingTrasmission = false));
    },
    getinitials(name) {
      const ii = name.split(" ");
      if (ii.length >= 2) {
        return ii[0].substring(0, 1) + ii[1].substring(0, 1);
      } else {
        return ii[0].substring(0, 2);
      }
    },
    appointmentCancel() {
      if (!this.operation.studio_main) {
        this.$utils.toastError(
          "OPS!!!",
          "Estúdio de apoio não pode cancelar operação"
        );
        return;
      }

      if (this.cancelTxt <= 5) {
        this.$utils.toastError(
          "OPS!!!",
          "A descrição precisa ter pelo menos 5 caracteres"
        );
        return;
      }

      this.cancelLoading = true;

      const payload = {
        reason_desc: this.cancelTxt,
        id: this.operation.appointment_id,
        appointment_id: this.operation.appointment_id,
      };

      _appointmentService
        .cancelReason(payload)
        .then(() => {
          this.$utils.toast("Notificação", "Operação Cancelada");
          this.$root.$emit("operatorFilter");
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.cancelLoading = true;
          this.$refs["modal-cancel-operation"].hide();
        });
    },
    getclass(operation) {
      if(operation && operation.operator_backup_id === this.currentOperator && !this.isCentralTech)
        return "warning";

      // CORE
      if (
        operation &&
        Object.keys(operation).some((x) => x == "operators_appointment_status")
      ) {
        switch (operation.operators_appointment_status) {
          case "Pendente de inicialização":
            return "info";
          case "Finalizado":
            return "secondary";
          case "Iniciado":
            return "success";
          default:
            return "dark";
        }
      } else {
        return "dark";
      }
    },
    canExclusionByOperation() {
      // CORE
      _recordingMediaService
        .canExclusionByOperation(this.operation.recording_media_id)
        .then((res) => {
          if (res.content) {
            this.onClickUnlockUpload();
          } else {
            this.$utils.toastError(
              "Notificação",
              "Gravação contém material. A operação não pode ser finalizada sem o upload de material"
            );
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    controlUnlockUpload() {
      //-> Método espelhado no componente "ShortCard.vue"
      if (
        this.operation.recording_media_id &&
        this.operation.recording_media_id > 0 &&
        !this.operation.recording_media_excluded &&
        this.operation.studio_main
      ) {
        if (!this.operation.ignore_upload) {
          this.canExclusionByOperation();
        } else {
          this.onClickUnlockUpload();
        }
      } else {
        this.onClickUnlockUpload();
      }
    },
    onClickUnlockUpload() {
      // CORE
      let payload = [
        {
          path: "/ignore_upload",
          op: "replace",
          value: !this.operation.ignore_upload,
        },
      ];
      _operatorService
        .ignoreUpload(this.operation.id, payload)
        .then((res) => {
          if (res.content) {
            this.operation.ignore_upload = res.content.ignore_upload;
            if (
              this.operation.recording_media_id &&
              this.operation.recording_media_id > 0 &&
              this.operation.studio_main
            ) {
              if (!res.content.ignore_upload) {
                this.reactivateRecording();
              } else {
                this.exclusionLogicalRecording();
              }
            } else {
              this.$utils.toast(
                "Sucesso",
                res.content.ignore_upload
                  ? "A operação pode ser finalizada sem o upload de material"
                  : "Upload de material obrigatório"
              );
            }
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    exclusionLogicalRecording() {
      // CORE
      _recordingMediaService
        .exclusionLogicalByOperartion(this.operation.recording_media_id)
        .then(() => {
          this.operation.recording_media_excluded = true;
          this.$utils.toast(
            "Sucesso",
            "A operação pode ser finalizada sem o upload de material"
          );
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    reactivateRecording() {
      // CORE
      _recordingMediaService
        .reactivate(this.operation.recording_media_id)
        .then((res) => {
          if (res.content) {
            this.operation.recording_media_excluded = false;
            this.$utils.toast(
              "Sucesso",
              "Upload de material obrigatório. Gravação foi reativada"
            );
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    onClickOpenChecklist(_record, _type) {
      // CORE
      this.checklist = {};
      let params = {
        checkList: { type: _type },
        appointment_studios_operator_id: _record.id,
      };
      this.isloading = true;
      _checkListService
        .findChecklist(params)
        .then((res) => {
          this.$refs["modal-checklist-alert-card"].hide();
          this.checklist = res.content;
          this.checklist.appointment_studios_operator_id = _record.id;
          this.$refs["modal-checklist-card"].show();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    onFinishNoExecution(_record, _type) {
      // CORE
      this.appointmentSelected = _record;
      this.$refs["modal-checklist-alert-card"].show();
    },
    saveAnswerCard(checklist) {
      // CORE
      this.loading = true;
      let entity = {
        data: {
          operator_id: this.operation.operator_id,
          checklist_id: checklist.id,
          workshift_id: checklist.workshift_id,
          appointment_studios_operator_id:
            checklist.appointment_studios_operator_id,
        },
      };

      _checkListOperatorAnswerService
        .save(entity)
        .then(() => {
          this.$refs["modal-checklist-card"].hide();
          if (
            checklist.appointment_studios_operator_id &&
            checklist.appointment_studios_operator_id > 0
          ) {
            let record = {
              id: checklist.appointment_studios_operator_id,
            };

            //START A DEMANDA
            if (checklist.type == 0) {
              this.start(record);
            } else if (checklist.type == 2) {
              if (
                this.operation.recording_media_id &&
                this.operation.recording_media_id > 0 &&
                !this.operation.recording_media_excluded &&
                this.operation.studio_main
              ) {
                this.canFinish(this.operation); // finalizar gravação junto com a finalização
              } else if (
                this.operation.required_upload_material &&
                !this.operation.ignore_upload &&
                this.operation.studio_main
              ) {
                this.$emit("materialUpload", this.operation);
              } else {
                this.execfinish({ id: this.operation.id });
              }
            } else {
              this.getRecords();
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    start(record) {
      this.loading = true;
      let entity = {
        appointment_studios_operator_id: record.id,
      };
      _operatorService
        .operationPlay(entity)
        .then(() => {
          this.$utils.toast("Notificação", "Iniciado com sucesso.");
          this.getRecords();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    execfinish(record) {
      // CORE
      this.loading = true;
      let entity = {
        appointment_studios_operator_id: record.id,
      };
      _operatorService
        .operationFinish(entity)
        .then(() => {
          this.$utils.toast("Notificação", "Finalizado com sucesso.");
          this.getRecords();
          this.$emit("showOcurrences", {
            appointmentID: this.operation.appointment_id,
            teachers: this.teachers,
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    canFinish(_record) {
      // CORE
      this.isloading = true;

      // finish já valida a gravação
      _recordingMediaService
        .finish(_record.recording_media_id)
        .then((res) => {
          if (res.content) {
            this.execfinish({ id: _record.id });
          } else {
            this.isloading = false;
            this.$utils.toastError(
              "Notificação",
              "Existe material sem informação de assunto ou há pendencias na gravação"
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally();
    },
    controlChecklistFinalized(_record, _type, _operation) {
      // CORE
      this.checklist = {};
      let params = {
        checkList: { type: _type },
        appointment_studios_operator_id: _record.id,
      };
      this.isloading = true;
      _checkListService
        .findChecklist(params)
        .then((res) => {
          this.$refs["modal-checklist-alert-card"].hide();
          if (res.content.already_answered) {
            if (res.content.type == 2) {
              if (
                _operation.required_upload_material &&
                !_operation.ignore_upload &&
                _operation.studio_main
              ) {
                this.canFinish(_operation);
              } else {
                this.execfinish({ id: _record.id });
              }
            }
          } else {
            this.checklist = res.content;
            this.checklist.appointment_studios_operator_id = _record.id;
            this.$refs["modal-checklist-card"].show();
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    populardropdown(_list, _texto, _valor, _email) {
      let retorno = [];
      retorno.push(
        ..._list.map((m) => {
          return {
            label: m[_texto],
            value: m[_valor],
            email: m[_email],
          };
        })
      );
      return retorno;
    },
    clipboardCopy(value) {
      navigator.clipboard.writeText(value);
    },
  },
};
</script>

<style scoped>
.shortcard {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1) !important;
  border-radius: 5px;
  border-top-width: 5px;
  border-top-style: solid;
  padding: 10px 20px;
}
.shortcard-warning{
  border-top-color: #f5912c;
}
.shortcard-info {
  border-top-color: #1990ff;
}
.shortcard-secondary {
  border-top-color: #545f70;
}
.shortcard-success {
  border-top-color: #28c76f;
}
.shortcard-pending {
  border-right-style: solid;
  border-left-style: solid;
  border-bottom-style: solid;
  border-right-width: 1px;
  border-left-width: 1px;
  border-bottom-width: 1px;
  border-right-color: #ea5455;
  border-left-color: #ea5455;
  border-bottom-color: #ea5455;
  color: #ea5455;
}
.shortcard-dark {
  border-top-color: #4b4b4b;
}
</style>

<style>
#small-popover .popover-body {
  background: #1990ff !important;
  border-color: #1990ff !important;
  border-top-right-radius: 0.358rem !important;
  border-top-left-radius: 0.358rem !important;
}
.tooltip {
  z-index: 10000;
}
</style>
