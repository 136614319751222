<!-- AVISO
Se for realizar alguma modificação aqui, pode ser necessário realizar
a mesma alteração no componente 'ShortCard.vue'
-->
<template>
  <b-overlay :variant="skin" :show="isloading" rounded="sm">
    <!-- MODAL DE CHECKLIST CARD-->
    <b-modal
      ref="modal-checklist-card"
      :title="`${checklist.name}`"
      hide-footer
      centered
      size="lg"
      no-close-on-backdrop
    >
      <div class="row">
        <div
          class="col-12"
          v-for="(steps, index) in checklist.listSteps"
          :key="index"
        >
          <span>
            {{ steps.step }}
          </span>
        </div>
        <br />
        <b-row style="margin-top: 10px" v-if="checklist.id > 0">
          <b-col cols="12" v-if="checklist.already_answered">
            <b-button variant="info" class="mr-1" disabled="true">
              Checklist Finalizado
            </b-button>
          </b-col>
          <b-col cols="12" v-else>
            <button--c
              :variant="'info'"
              :iconsize="'20'"
              :icon="null"
              :permission="'permission.appointment.operator.view'"
              :title="'Finalizar Checklist'"
              @clicked="saveAnswerCard(checklist)"
              class="mr-1"
            />
          </b-col>
        </b-row>
      </div>
    </b-modal>

    <!-- MODAL DE CHECKLIST ALERTA  CARD-->
    <b-modal
      ref="modal-checklist-alert-card"
      title="Alerta"
      hide-footer
      centered
      no-close-on-backdrop
    >
      <div class="pb-25 row">
        <div class="mb-3 col-12">
          O CheckList de Execução <b>não foi preenchido,</b> deseja continuar
          assim mesmo?
        </div>
        <b-col style="text-align: left">
          <!--CONTINUAR 2 -->
          <b-button @click="onClickOpenChecklist(appointmentSelected, 2)"
            >CONTINUAR</b-button
          >
        </b-col>
        <b-col style="text-align: right">
          <!--PREENCHER 1 -->
          <b-button @click="onClickOpenChecklist(appointmentSelected, 1)"
            >PREENCHER</b-button
          >
        </b-col>
      </div>
    </b-modal>

    <!-- MODAL TROCA DE OPERADOR -->
    <b-modal
      ref="modal-change-operator"
      title="Deseja trocar operador ?"
      hide-footer
      size="lg"
      centered
      @hide="onHideModal"
      cancel-title="Fechar"
    >
      <div class="row">
        <div class="col-12">
          <v-select
            v-model="operatorsSelected"
            :options="operators"
            autocomplete="off"
            :clearable="false"
            @search="getOperators"
            searchable
            :loading="isLoadingOperator"
          />
        </div>
      </div>

      <b-row style="margin-bottom: 8px; margin-top: 50px">
        <b-col style="text-align: right">
          <b-button @click="onClickChangeOperatorSave(operation)"
            >Salvar</b-button
          >
        </b-col>
      </b-row>
    </b-modal>

    <!-- CARD BODY -->
    <b-card
      header-tag="header"
      footer-tag="footer"
      :header-bg-variant="getclass(operation)"
      :header-border-variant="getOperatorPendency(isOperatorPendency)"
      :border-variant="getOperatorPendency(isOperatorPendency)"
      :body-text-variant="getOperatorPendency(isOperatorPendency)"
      align="center"
      style="border-radius: 21px"
      :body-class="['card-custom-body', `card-custom-body-${skin}`]"
    >
      <template #header>
        <div
          v-if="operation"
          class="text-left text-truncate"
          style="width: 100%; color: white"
        >
          <b>{{ operation.studio_name || "--" }}</b> <br />
          Operador: {{ operation.operator_name || "--" }} <br />
          Backup: {{ operation.operator_backup_name || "--" }} <br />
          {{ operation.company_name || "--" }} <br />
          ID: {{ operation.id }}
        </div>
        <div v-if="operation" class="appointment-status text-capitalize">
          {{ operation.operators_appointment_status || "--" }}
        </div>
      </template>

      <b-card-body body-class="pt-25">
        <b-tabs pills justified>
          <b-tab @click="getTrasmission">
            <template #title>
              <span>Transmissões</span>
            </template>
            <b-overlay :variant="skin" :show="isloadingTrasmission">
              <b-row>
                <b-col cols="12">
                  <div class="chat-widget">
                    <section class="chat-app-window">
                      <!-- Scroll Area -->
                      <vue-perfect-scrollbar
                        ref="refChatLogPS"
                        :settings="perfectScrollbarSettings"
                        class="user-chats scroll-area"
                        style="height: 284px"
                      >
                        <div
                          v-if="
                            operation &&
                            operation.appointment &&
                            operation.links
                          "
                        >
                          <div
                            v-for="link in operation.links"
                            :key="link.id"
                            :class="[
                              'link-card',
                              'text-left',
                              `link-card-bg-${skin}`,
                            ]"
                          >
                            <h4>
                              {{ operation.appointment.jobtype_name || "--" }}
                            </h4>
                            <div class="d-flex justify-content-between">
                              <div style="width: 50%">
                                <span style="font-size: 9pt"> Canal: </span>
                                <br />
                                <b>
                                  {{ link.transmission_type_name || "--" }}
                                </b>
                              </div>
                              <div style="width: 50%">
                                <span style="font-size: 9pt"> Empresa: </span>
                                <br />
                                <b> {{ link.company_name || "--" }} </b>
                              </div>
                            </div>
                            <div class="d-flex justify-content-between mt-50">
                              <div class="main-transmission">
                                <span style="font-size: 9pt">
                                  Titulo da transmissão:
                                </span>
                                <br />
                                <b class="transmission">
                                  {{ link.youtube_title || "--" }}
                                </b>
                              </div>
                              <b-button
                                v-if="link.youtube_title"
                                size="sm"
                                v-clipboard:copy="link.youtube_title"
                                style="height: 30px"
                                class="mt-50"
                              >
                                Copiar
                              </b-button>
                            </div>
                            <div class="d-flex justify-content-between mt-50">
                              <div class="main-transmission">
                                <span style="font-size: 9pt">
                                  Link da transmissão:
                                </span>
                                <br />
                                <b class="transmission">
                                  {{ link.youtube_url || "--" }}
                                </b>
                              </div>
                              <b-button
                                v-if="link.youtube_url"
                                size="sm"
                                v-clipboard:copy="link.youtube_url"
                                style="height: 30px"
                                class="mt-50"
                              >
                                Copiar
                              </b-button>
                            </div>
                            <div class="d-flex justify-content-between mt-50">
                              <div class="main-transmission">
                                <span style="font-size: 9pt">
                                  Chave da transmissão:
                                </span>
                                <br />
                                <b class="transmission"> {{ "--" }} </b>
                              </div>
                              <b-button
                                size="sm"
                                style="height: 30px"
                                class="mt-50"
                              >
                                Copiar
                              </b-button>
                            </div>
                          </div>
                        </div>
                        <p v-else class="mt-2">Sem links de transmissão</p>
                      </vue-perfect-scrollbar>
                    </section>
                  </div>
                </b-col>
              </b-row>
            </b-overlay>
          </b-tab>

          <b-tab class="pb-0" active>
            <template #title>
              <span>Dados Básicos</span>
            </template>

            <div class="mt-2 d-flex">
              <div
                v-if="appointment && appointment.appointment_start"
                class="event-day"
              >
                <b style="font-size: 20px">
                  {{
                    weekdays[new Date(appointment.appointment_start).getDay()]
                  }}
                </b>
                <b style="font-size: 50px">
                  {{
                    new Date(appointment.appointment_start).getDate().toString()
                      .length > 1
                      ? new Date(appointment.appointment_start).getDate()
                      : "0" + new Date(appointment.appointment_start).getDate()
                  }}
                </b>
              </div>

              <b-card-text
                v-if="operation && operation.appointment"
                class="pl-1 pt-25 text-left"
                style="max-width: 80%"
              >
                <h4 class="text-truncate">
                  {{ operation.event_course || "--" }}
                </h4>
                <div>{{ operation.appointment.jobtype_name || "--" }}</div>
                <div>
                  {{ operation.appointment.start_time || "--" }} -
                  {{ operation.appointment.end_time || "--" }}
                </div>
                <b-link
                  class="underline"
                  :to="`/pedagogic/calendar?q=${operation.appointment_id}`"
                  >#{{ operation.appointment_id }}</b-link
                >
              </b-card-text>
            </div>

            <div
              class="mt-2 d-flex justify-content-between"
              style="height: 30px"
            >
              <b-button
                v-if="operation && operation.event_id"
                variant="primary"
                size="sm"
                @click="$emit('showTimeline', operation.appointment_id)"
              >
                <feather-icon icon="ChevronRightIcon" class="mr-25" />
                <span class="align-middle">Cronograma</span>
              </b-button>
              <b-button
                v-if="
                  operation &&
                  operation.recording_media_id &&
                  !operation.recording_media_excluded
                "
                variant="warning"
                size="sm"
                :to="`/audiovisual/recording/view/${operation.recording_media_id}`"
              >
                <feather-icon icon="ChevronRightIcon" class="mr-25" />
                <span class="align-middle"
                  >Gravação #{{ operation.recording_media_id }}</span
                >
              </b-button>
            </div>

            <div
              v-if="
                operation &&
                operation.appointment &&
                operation.appointment.participants
              "
              :class="['mt-2', 'p-1', 'teachers', `teachers-bg-${skin}`]"
            >
              <b-card-text
                class="d-flex justify-content-start align-items-center"
                v-for="(participants, id) in operation.appointment.participants"
                :key="id"
              >
                <b-avatar
                  variant="secondary"
                  class="mr-50"
                  badge-top
                  badge-offset="-5px"
                  badge-variant="light"
                >
                  <template v-if="participants.type == 'anchor'" #badge>
                    <feather-icon icon="AnchorIcon" />
                  </template>
                </b-avatar>
                <div class="text-truncate">{{ participants.name }}</div>
              </b-card-text>
              <div
                class="d-flex justify-content-between align-items-center more-teachers cursor-pointer"
                @click="
                  $emit('showParticipants', operation.appointment.participants)
                "
              >
                <div
                  v-if="
                    operation &&
                    operation.appointment &&
                    operation.appointment.participants &&
                    operation.appointment.participants.length > 2
                  "
                  class="pr-1"
                  style="color: white"
                >
                  +{{ operation.appointment.participants.length - 2 }}
                </div>
                <b-avatar variant="secondary" size="sm" />
              </div>
            </div>
          </b-tab>

          <b-tab @click="getChat">
            <template #title>
              <span>Avisos</span>
            </template>
            <Chat
              :loading="isloadingChat"
              :data="chatData"
              @sendMessage="sendMessage"
            />
          </b-tab>
        </b-tabs>
      </b-card-body>

      <div
        style="min-height: 87px"
        class="d-flex justify-content-center align-items-end"
      >
        <!-- UPLOAD DE MATERIAL -->
        <span
          :id="`upload-span-${operation.id}`"
          v-if="
            operation &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status == 'Iniciado' &&
            operation.required_upload_material &&
            !operation.ignore_upload
          "
        >
          <b-button
            :id="`upload-buttom-${operation.id}`"
            variant="relief-info"
            squared
            class="pt-2 pb-2 mx-25"
            @click="$emit('materialUpload', operation)"
            :disabled="
              !operation.studio_main || disabledActionButton(operation)
            "
          >
            <feather-icon icon="UploadIcon" />
          </b-button>
          <b-tooltip
            triggers="hover"
            :title="
              !operation.studio_main
                ? 'Estúdio de apoio não pode enviar material'
                : 'Enviar Material'
            "
            :target="`upload-span-${operation.id}`"
          />
        </span>

        <!-- OBRIGATORIEDADE DE UPLOAD -->
        <b-button
          v-if="
            operation &&
            operation.required_upload_material &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status != 'Finalizado' &&
            isCentralTech &&
            operation.studio_main
          "
          :id="`unlock-buttom-${operation.id}`"
          variant="relief-info"
          squared
          @click="controlUnlockUpload()"
          class="pt-2 pb-2 mx-25"
          :disabled="disabledActionButton(operation)"
        >
          <feather-icon
            :icon="!operation.ignore_upload ? 'LockIcon' : 'UnlockIcon'"
          />
          <b-tooltip
            triggers="hover"
            title="Obrigatoriedade de Upload"
            :target="`unlock-buttom-${operation.id}`"
          />
        </b-button>

        <!-- PLAY -->
        <b-button
          v-if="
            operation &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status ==
              'Pendente de inicialização'
          "
          :id="`start-buttom-${operation.id}`"
          variant="relief-info"
          squared
          @click="onClickOpenChecklist(appointment, 0)"
          class="pt-2 pb-2 mx-25"
          :disabled="disabledActionButton(operation)"
        >
          <feather-icon icon="PlayIcon" />
          <b-tooltip
            triggers="hover"
            title="Assumir Demanda"
            :target="`start-buttom-${operation.id}`"
          />
        </b-button>

        <!-- FINALIZAR DEMANDA -->
        <b-button
          v-if="
            operation &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status == 'Iniciado'
          "
          :id="`finish-buttom-${operation.id}`"
          variant="relief-info"
          squared
          @click="
            operation.checklist_execution_finalized
              ? controlChecklistFinalized(appointment, 2, operation)
              : onFinishNoExecution(operation, 1)
          "
          class="pt-2 pb-2 mx-25"
          :disabled="disabledActionButton(operation)"
        >
          <feather-icon icon="StopCircleIcon" />
          <b-tooltip
            triggers="hover"
            title="Finalizar Demanda"
            :target="`finish-buttom-${operation.id}`"
          />
        </b-button>

        <!-- OCORRENCIAS -->
        <b-button
          v-if="operation && operation.operators_appointment_status"
          :id="`occurrence-buttom-${operation.id}`"
          variant="relief-info"
          squared
          @click="
            $emit('showOcurrences', {
              appointmentID: operation.appointment_id,
              teachers,
            })
          "
          class="pt-2 pb-2 mx-25"
          :disabled="disabledActionButton(operation)"
        >
          <feather-icon icon="FileTextIcon" />
          <b-tooltip
            triggers="hover"
            title="Ver Ocorrências"
            :target="`occurrence-buttom-${operation.id}`"
          />
        </b-button>

        <!-- CANCELAR OPERAÇÃO -->
        <span
          :id="`cancel-appointment-span-${operation.id}`"
          v-if="
            operation &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status != 'Finalizado' &&
            isCentralTech
          "
        >
          <b-button
            :id="`cancel-appointment-buttom-${operation.id}`"
            variant="relief-info"
            squared
            class="pt-2 pb-2 mx-25"
            :disabled="disabledActionButton(operation)"
          >
            <feather-icon icon="XIcon" />
          </b-button>
          <b-tooltip
            :title="
              !operation.studio_main
                ? 'Estúdio de apoio não pode cancelar operação'
                : 'Cancelar Operação'
            "
            triggers="hover"
            :target="`cancel-appointment-span-${operation.id}`"
          />
        </span>
        <b-popover
          v-if="
            operation &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status != 'Finalizado'
          "
          :target="`cancel-appointment-buttom-${operation.id}`"
          triggers="focus"
          placement="right"
          variant="secondary"
        >
          <template #title>
            Cancelar Operação #{{ operation.appointment_id }}
          </template>
          <div style="min-width: 200px">
            <b-form-textarea
              v-model="cancelTxt"
              :state="cancelTxt.length > 5"
              placeholder="Breve Descrição..."
              rows="3"
            />
          </div>

          <div class="d-flex justify-content-end m-0 p-0">
            <b-button
              size="sm"
              variant="danger"
              class="mt-50"
              style="min-width: 100px"
              @click="appointmentCancel"
              :disabled="cancelTxt.length <= 5"
            >
              <b-spinner v-if="cancelLoading" small label="Small Spinner" />
              <span v-else>Continuar</span>
            </b-button>
          </div>
        </b-popover>

        <!-- CHECKLIST DE EXECUÇÃO -->
        <b-button
          v-if="
            operation &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status == 'Iniciado'
          "
          :id="`execution-buttom-${operation.id}`"
          variant="relief-info"
          squared
          @click="onClickOpenChecklist(appointment, 1)"
          class="pt-2 pb-2 mx-25"
          :disabled="disabledActionButton(operation)"
        >
          <feather-icon icon="CheckCircleIcon" />
          <b-tooltip
            triggers="hover"
            title="CheckList Execução"
            :target="`execution-buttom-${operation.id}`"
          />
        </b-button>

        <!-- TROCAR OPERADOR -->
        <b-button
          v-if="
            operation &&
            operation.operators_appointment_status &&
            operation.operators_appointment_status != 'Finalizado' &&
            isCentralTech
          "
          :id="`change-button-${operation.id}`"
          variant="relief-info"
          squared
          @click="onClickChangeOperator(operation)"
          class="pt-2 pb-2 mx-25"
          :disabled="disabledActionButton(operation)"
        >
          <feather-icon icon="UserIcon" />
          <b-tooltip
            triggers="hover"
            title="Trocar operador"
            :target="`change-button-${operation.id}`"
          />
        </b-button>
      </div>
    </b-card>
  </b-overlay>
</template>

<script>
import _appointmentService from "@/services/appointment-service";
import _operatorService from "@/services/appointment-studios-operator";
import _operatorsService from "@/services/operators-service";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import Chat from "./Chat.vue";
import ChatLog from "@/views/apps/chat/ChatLog.vue";
import formAppointment from "./FormAppointment.vue";
import _checkListService from "@/services/audiovisual/check-list-service";
import _checkListOperatorAnswerService from "@/services/audiovisual/check-list-operator-answer-service";
import _recordingMediaService from "@/services/audiovisual/recording-media";
import useAppConfig from "@core/app-config/useAppConfig";
import {
  BCardBody,
  BCard,
  BCardText,
  BLink,
  BImg,
  BListGroup,
  BListGroupItem,
  BCardTitle,
  BTooltip,
  BTabs,
  BTab,
  BAvatar,
  VBPopover,
  BPopover,
  BFormTextarea,
  BSpinner,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BButton,
  BButtonGroup,
  VBTooltip,
  BBadge,
} from "bootstrap-vue";
export default {
  setup() {
    const { skin } = useAppConfig();
    return {
      skin,
    };
  },
  components: {
    BCardBody,
    BCard,
    BCardText,
    BLink,
    BImg,
    BListGroup,
    BListGroupItem,
    BCardTitle,
    BTooltip,
    BTabs,
    BTab,
    BAvatar,
    BPopover,
    BFormTextarea,
    BSpinner,
    BOverlay,
    VuePerfectScrollbar,
    ChatLog,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BFormInput,
    BButton,
    BButtonGroup,
    VBTooltip,
    formAppointment,
    Chat,
    BBadge,
  },
  directives: {
    "b-popover": VBPopover,
    "b-tooltip": VBTooltip,
  },
  props: {
    isOperatorPendency: {
      type: Boolean,
      required: false,
    },
    isCentralTech: {
      type: Boolean,
      required: false,
    },
    appointment: {
      type: Object,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
    trasmissionTypes: {
      type: Array,
      required: true,
    },
    currentOperator: {
      type: Number,
      required: false,
    },
  },
  created() {
    this.getRecords();
  },
  data() {
    return {
      isloading: false,
      isloadingChat: false,
      isloadingTrasmission: false,
      isLoadingOperator: false,
      chatData: null,
      perfectScrollbarSettings: {
        maxScrollbarLength: 150,
        wheelPropagation: false,
      },
      operation: null,
      cancelTxt: "",
      cancelLoading: false,
      weekdays: ["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"],
      checklist: {
        name: "",
        id: 0,
        listSteps: [],
      },
      appointmentSelected: null,
      operators: [],
      operatorsSelected: [{ label: "Selecione", value: "-1" }],
      teachers: [],
    };
  },
  methods: {
    disabledActionButton(operation) {
      if (!this.isCentralTech) {
        return !(
          this.currentOperator > 0 &&
          operation &&
          this.currentOperator == operation.operator_id
        );
      } else {
        return false;
      }
    },
    onHideModal(evt) {
      if (evt.trigger === "backdrop") {
        evt.preventDefault();
      }
    },
    onClickChangeOperator() {
      // CORE
      this.$refs["modal-change-operator"].show();
    },
    getOperators(_text) {
      // CORE
      if (_text && _text.length >= 3 && !this.isloadingStudio) {
        let filter = {
          search: _text,
          ascOrDes: true,
          orderByKey: "name",
        };
        setTimeout(() => {
          this.isLoadingOperator = true;
          _operatorsService
            .show(1, filter)
            .then((res) => {
              if (res.content) {
                this.operators = res.content.map((m) => {
                  return {
                    label: m.name,
                    value: m.id,
                  };
                });
              }
            })
            .catch((error) => this.$utils.toastError("Notificação", error))
            .finally(() => (this.isLoadingOperator = false));
        }, 500);
      }
    },
    onClickChangeOperatorSave(_operation) {
      // CORE
      if (this.operatorsSelected && this.operatorsSelected.value !== "-1") {
        let payload = [
          {
            path: "/operator_id",
            op: "replace",
            value: this.operatorsSelected.value,
          },
        ];
        this.$refs["modal-change-operator"].hide();
        this.isloading = true;
        _operatorService
          .changeOperator(_operation.id, payload)
          .then((res) => {
            if (res.content) {
              this.getRecords();
            }
          })
          .catch((error) => this.$utils.toastError("Notificação", error));
      } else {
        this.$utils.toastError("Operador");
      }
    },
    teachersToArray(content) {
      // CORE
      if (content && content.teachers) {
        this.teachers = this.populardropdown(
          content.teachers,
          "teacher_name",
          "teacher_id",
          "teacher_email"
        );
      }
    },
    getRecords() {
      // CORE
      this.isloading = true;
      _operatorService
        .operationDetails(this.appointment.id)
        .then((res) => {
          if (res.content) {
            this.operation = res.content;
            this.teachersToArray(res.content);
            this.getAppointmentDetails();
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error));
    },
    getAppointmentDetails() {
      // CORE
      _appointmentService
        .find_short(this.operation.appointment_id)
        .then((res) => {
          if (res.content) {
            this.operation.appointment = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => {
          this.isloading = false;
          this.$emit("finish");
        });
    },
    getChat() {
      // CORE
      this.isloadingChat = true;
      this.chatData = {
        chat: {
          id: 0,
          chat: [],
        },
        contact: {
          id: 0,
        },
      };
      this.chatData.chat.id = this.operation.appointment_id;
      _appointmentService
        .getChat(this.operation.appointment_id)
        .then((res) => {
          this.chatData.contact.id = res.content.user_id;
          res.content.chats.forEach((chat) => {
            this.chatData.chat.chat.push({
              message: chat.message,
              time: chat.created_at,
              senderId: chat.user_id,
              userName: chat.user_name,
              userNameInitial: this.getinitials(chat.user_name),
            });
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloadingChat = false));
    },
    sendMessage(inputMessage) {
      //CORE
      if (inputMessage) {
        this.isloadingChat = true;
        const payload = {
          data: {
            message: inputMessage,
            appointment_id: this.operation.appointment_id,
          },
        };
        _appointmentService
          .createChat(payload)
          .then((res) => {
            this.chatData.chat.chat.push({
              message: inputMessage,
              time: new Date(),
              senderId: this.chatData.contact.id,
              userName: res.content,
              userNameInitial: this.getinitials(res.content),
            });
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.isloadingChat = false));
      }
    },
    getTrasmission() {
      // CORE
      this.isloadingTrasmission = true;
      _appointmentService
        .find_short_links(this.operation.appointment_id)
        .then((res) => {
          if (res.content && res.content.length > 0) {
            res.content.forEach((link) => {
              if (this.companies.some((comp) => comp.id == link.company_id)) {
                let company = this.companies.filter(
                  (comp) => comp.id == link.company_id
                );
                link.company_name = company[0].name;
              }
              if (
                this.trasmissionTypes.some(
                  (trans) => trans.id == link.transmission_type_id
                )
              ) {
                let transmission = this.trasmissionTypes.filter(
                  (comp) => comp.id == link.transmission_type_id
                );
                link.transmission_type_name = transmission[0].name;
              }
            });
            this.operation.links = res.content;
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloadingTrasmission = false));
    },
    getinitials(name) {
      const ii = name.split(" ");
      if (ii.length >= 2) {
        return ii[0].substring(0, 1) + ii[1].substring(0, 1);
      } else {
        return ii[0].substring(0, 2);
      }
    },
    appointmentCancel() {
      if (!this.operation.studio_main) {
        this.$utils.toastError(
          "OPS!!!",
          "Estúdio de apoio não pode cancelar operação"
        );
        return;
      }

      if (this.cancelTxt <= 5) {
        this.$utils.toastError(
          "OPS!!!",
          "A descrição precisa ter pelo menos 5 caracteres"
        );
        return;
      }

      this.cancelLoading = true;

      const payload = {
        reason_desc: this.cancelTxt,
        id: this.operation.appointment_id,
        appointment_id: this.operation.appointment_id,
      };

      _appointmentService
        .cancelReason(payload)
        .then(() => {
          this.$utils.toast("Notificação", "Operação Cancelada");
          this.$root.$emit("operatorFilter");
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.cancelLoading = true));
    },
    getclass(operation) {
      //isCentralTech || (currentOperator > 0 && operation && currentOperator == operation.operator_id)

      if (
        operation &&
        operation.operator_backup_id === this.currentOperator &&
        !this.isCentralTech
      )
        return "warning";

      // CORE
      if (
        operation &&
        Object.keys(operation).some((x) => x == "operators_appointment_status")
      ) {
        switch (operation.operators_appointment_status) {
          case "Pendente de inicialização":
            return "info";
          case "Finalizado":
            return "secondary";
          case "Iniciado":
            return "success";
          default:
            return "dark";
        }
      } else {
        return "dark";
      }
    },
    getOperatorPendency(isOperatorPendency) {
      // CORE
      return isOperatorPendency ? "danger" : "";
    },
    canExclusionByOperation() {
      // CORE
      _recordingMediaService
        .canExclusionByOperation(this.operation.recording_media_id)
        .then((res) => {
          if (res.content) {
            this.onClickUnlockUpload();
          } else {
            this.$utils.toastError(
              "Notificação",
              "Gravação contém material. A operação não pode ser finalizada sem o upload de material"
            );
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    controlUnlockUpload() {
      //-> Método espelhado no componente "ShortCard.vue"
      if (
        this.operation.recording_media_id &&
        this.operation.recording_media_id > 0 &&
        !this.operation.recording_media_excluded &&
        this.operation.studio_main
      ) {
        if (!this.operation.ignore_upload) {
          this.canExclusionByOperation();
        } else {
          this.onClickUnlockUpload();
        }
      } else {
        this.onClickUnlockUpload();
      }
    },
    onClickUnlockUpload() {
      // CORE
      let payload = [
        {
          path: "/ignore_upload",
          op: "replace",
          value: !this.operation.ignore_upload,
        },
      ];
      _operatorService
        .ignoreUpload(this.operation.id, payload)
        .then((res) => {
          if (res.content) {
            this.operation.ignore_upload = res.content.ignore_upload;
            if (
              this.operation.recording_media_id &&
              this.operation.recording_media_id > 0 &&
              this.operation.studio_main
            ) {
              if (!res.content.ignore_upload) {
                this.reactivateRecording();
              } else {
                this.exclusionLogicalRecording();
              }
            } else {
              this.$utils.toast(
                "Sucesso",
                res.content.ignore_upload
                  ? "A operação pode ser finalizada sem o upload de material"
                  : "Upload de material obrigatório"
              );
            }
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    exclusionLogicalRecording() {
      // CORE
      _recordingMediaService
        .exclusionLogicalByOperartion(this.operation.recording_media_id)
        .then(() => {
          this.operation.recording_media_excluded = true;
          this.$utils.toast(
            "Sucesso",
            "A operação pode ser finalizada sem o upload de material"
          );
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    reactivateRecording() {
      // CORE
      _recordingMediaService
        .reactivate(this.operation.recording_media_id)
        .then((res) => {
          if (res.content) {
            this.operation.recording_media_excluded = false;
            this.$utils.toast(
              "Sucesso",
              "Upload de material obrigatório. Gravação foi reativada"
            );
          }
        })
        .catch((error) => {
          this.$utils.toastError("Notificação", error);
        });
    },
    onClickOpenChecklist(_record, _type) {
      // CORE
      this.checklist = {};
      let params = {
        checkList: { type: _type },
        appointment_studios_operator_id: _record.id,
      };
      this.isloading = true;
      _checkListService
        .findChecklist(params)
        .then((res) => {
          this.$refs["modal-checklist-alert-card"].hide();
          this.checklist = res.content;
          this.checklist.appointment_studios_operator_id = _record.id;
          this.$refs["modal-checklist-card"].show();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    onFinishNoExecution(_record, _type) {
      // CORE
      this.appointmentSelected = _record;
      this.$refs["modal-checklist-alert-card"].show();
    },
    saveAnswerCard(checklist) {
      // CORE
      this.loading = true;
      let entity = {
        data: {
          operator_id: this.operation.operator_id,
          checklist_id: checklist.id,
          workshift_id: checklist.workshift_id,
          appointment_studios_operator_id:
            checklist.appointment_studios_operator_id,
        },
      };

      _checkListOperatorAnswerService
        .save(entity)
        .then(() => {
          this.$refs["modal-checklist-card"].hide();
          if (
            checklist.appointment_studios_operator_id &&
            checklist.appointment_studios_operator_id > 0
          ) {
            let record = {
              id: checklist.appointment_studios_operator_id,
            };

            //START A DEMANDA
            if (checklist.type == 0) {
              this.start(record);
            } else if (checklist.type == 2) {
              if (
                this.operation.recording_media_id &&
                this.operation.recording_media_id > 0 &&
                !this.operation.recording_media_excluded &&
                this.operation.studio_main
              ) {
                this.canFinish(this.operation); // finalizar gravação junto com a finalização
              } else if (
                this.operation.required_upload_material &&
                !this.operation.ignore_upload &&
                this.operation.studio_main
              ) {
                this.$emit("materialUpload", this.operation);
              } else {
                this.execfinish({ id: this.operation.id });
              }
            } else {
              this.getRecords();
            }
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    start(record) {
      this.loading = true;
      let entity = {
        appointment_studios_operator_id: record.id,
      };
      _operatorService
        .operationPlay(entity)
        .then(() => {
          this.$utils.toast("Notificação", "Iniciado com sucesso.");
          this.getRecords();
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    async execfinish(record) {
      this.loading = true;
      try {
        let { content } = await _recordingMediaService.allCompletedProducts(
          record.id
        );
        if (!content.completed) {
          const products = content.incomplete_products.map((m) => m.name);
          this.$swal({
            title: "Produtos ainda não contemplados",
            text: `ATENÇÃO: Os produtos '${products.join(
              ", "
            )}' ainda não foram contemplados. Tem certeza que deseja finalizar a gravação?`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Sim, quero finalizar!",
            cancelButtonText: "Cancelar",
            customClass: {
              confirmButton: "btn btn-info",
              cancelButton: "btn btn-outline-danger ml-1",
            },
            buttonsStyling: false,
          }).then((result) => {
            if (result.value) {
              this.finish(record.id);
            }
          });
        } else {
          this.finish(record.id);
        }
      } catch (error) {
        this.$utils.toastError("Notificação", error);
        this.loading = false;
      }
    },
    finish(recordId) {
      this.loading = true;
      let entity = {
        appointment_studios_operator_id: recordId,
      };
      _operatorService
        .operationFinish(entity)
        .then(() => {
          this.$utils.toast("Notificação", "Finalizado com sucesso.");
          this.getRecords();
          this.$emit("showOcurrences", {
            appointmentID: this.operation.appointment_id,
            teachers: this.teachers,
          });
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.loading = false));
    },
    canFinish(_record) {
      // CORE
      this.isloading = true;

      // finish já valida a gravação
      _recordingMediaService
        .finish(_record.recording_media_id)
        .then((res) => {
          if (res.content) {
            this.execfinish({ id: _record.id });
          } else {
            this.isloading = false;
            this.$utils.toastError(
              "Notificação",
              "Existe material sem informação de assunto ou há pendencias na gravação"
            );
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally();
    },
    controlChecklistFinalized(_record, _type, _operation) {
      // CORE
      this.checklist = {};
      let params = {
        checkList: { type: _type },
        appointment_studios_operator_id: _record.id,
      };
      this.isloading = true;
      _checkListService
        .findChecklist(params)
        .then((res) => {
          this.$refs["modal-checklist-alert-card"].hide();
          if (res.content.already_answered) {
            if (res.content.type == 2) {
              if (
                _operation.required_upload_material &&
                !_operation.ignore_upload &&
                _operation.studio_main
              ) {
                this.canFinish(_operation);
              } else {
                this.execfinish({ id: _record.id });
              }
            }
          } else {
            this.checklist = res.content;
            this.checklist.appointment_studios_operator_id = _record.id;
            this.$refs["modal-checklist-card"].show();
          }
        })
        .catch((error) => this.$utils.toastError("Notificação", error))
        .finally(() => (this.isloading = false));
    },
    populardropdown(_list, _texto, _valor, _email) {
      let retorno = [];
      retorno.push(
        ..._list.map((m) => {
          return {
            label: m[_texto],
            value: m[_valor],
            email: m[_email],
          };
        })
      );
      return retorno;
    },
  },
};
</script>

<style scoped lang="scss">
.card .card {
  box-shadow: 0 4px 24px 0 rgb(34 41 47 / 10%) !important;
}
.buttons-center {
  text-align: center;
}
.buttons-center button {
  margin-right: 5px;
  border: 1px solid #4b4b4b !important;
}
.editor {
  height: 150pt;
  padding-bottom: 30px;
}
.float-text {
  position: absolute;
  bottom: 80px;
  right: 20px;
}
.spinner-floating {
  position: absolute;
  top: 164px;
  right: 35px;
}
.alert-floating {
  position: absolute;
  top: 156px;
  right: 25px;
}
.char-limit {
  display: block;
  position: absolute;
  right: 20px;
  bottom: 80px;
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
.event-day {
  padding: 3px 10px;
  height: 95px;
  width: 100px;
  border-right: #808080 solid 2px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
.card .card-header {
  border-top-right-radius: 20px !important;
  border-top-left-radius: 20px !important;
  padding: 18px !important;
}
.card .card-body {
  padding: 5px 10px 0px 10px !important;
}
.appointment-status {
  position: absolute;
  bottom: 18px;
  right: 18px;
  color: white;
  font-weight: bold;
}
.card-custom-body {
  min-height: 430px;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.card-custom-body-light {
  background: white;
}
.card-custom-body-dark {
  background: #454e53;
}
.teachers {
  width: 100%;
  height: 110px;
  border-radius: 10px;
  overflow: hidden;
}

.teachers-bg-light {
  background: #f0f0f0;
}

.teachers-bg-dark {
  background: #5d656a;
}

.more-teachers {
  padding: 5px 10px;
  display: flex;
  background: #4b4b4b;
  border-radius: 15px;
  position: absolute;
  bottom: 100px;
  right: 28px;
}

.link-card {
  border-radius: 10px;
  margin: 0px 0px 10px 0px;
  padding: 10px;
}

.link-card-bg-light {
  background: #e0e0e0;
}

.link-card-bg-dark {
  background: #383e42;
}
.main-transmission {
  flex: 1;
  position: relative;
  min-height: 40px;
}

.main-transmission .transmission {
  position: absolute;
  top: 20px;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding-right: 10px;
}
</style>
